import React from 'react';
import './ProfileSidebar.css';

import { ReactComponent as EditIcon } from '../../../assets/EditIcon.svg';
import { ReactComponent as EmailIcon } from '../../../assets/EmailIcon.svg';
import { ReactComponent as PhoneIcon } from '../../../assets/PhoneIcon.svg';
import { ReactComponent as BirthdayIcon } from '../../../assets/GiftIcon.svg';
import { ReactComponent as LocationIcon } from '../../../assets/LocationPin.svg';

import { ReactComponent as GitHubIcon } from '../../../assets/GitHubIcon.svg';
import { ReactComponent as LinkedInIcon } from '../../../assets/LinkedInIcon.svg';
import { ReactComponent as TwitterIcon } from '../../../assets/TwitterIcon.svg';
import { ReactComponent as InstagramIcon } from '../../../assets/InstagramIcon.svg';
import { ReactComponent as FacebookIcon } from '../../../assets/FacebookIcon.svg';

import { ReactComponent as FileIcon } from '../../../assets/FileIcon.svg';

const ProfileSidebar = () => {
  return (
    <div className="profile-sidebar">
      <div className="profile-sidebar-header">
        <div className="profile-header-content">
          <div className="profile-image-placeholder"></div>
          <div className="profile-text-content">
            <h3 className="profile-name">Jane Doe</h3>
            <p className="profile-role">Senior UX Designer</p>
          </div>
        </div>
      </div>
      <div className="personal-info">
        <div className="personal-info-title">
          <h4>Personal information</h4>
          <EditIcon />
        </div>
        <div className="personal-info-item">
          <div className="personal-info-icon-background">
            <EmailIcon />
          </div>
          <p>janedoe@example.com</p>
        </div>
        <div className="personal-info-item">
          <div className="personal-info-icon-background">
            <PhoneIcon />
          </div>
          <p>(415) 555-1234</p>
        </div>
        <div className="personal-info-item">
          <div className="personal-info-icon-background">
            <BirthdayIcon />
          </div>
          <div className="personal-info-birthday">
            <p>15.01.1990</p>
            <p className="personal-info-birthday-age">34 years old</p>
          </div>
        </div>
        <div className="personal-info-item">
          <div className="personal-info-icon-background">
            <LocationIcon />
          </div>
          <p>London, UK</p>
        </div>
      </div>
      <div className="social-media-links">
        <h4>Social media</h4>
        <div className="social-media-icons">
          <GitHubIcon />
          <LinkedInIcon />
          <TwitterIcon />
          <InstagramIcon />
          <FacebookIcon />
        </div>
      </div>

      <div className="profile-cv">
        <h4>CV</h4>
        <div className="cv-file">
          <p>Jane-Doe-CV.pdf</p>
          <FileIcon />
        </div>
      </div>

    </div>
  );
};

export default ProfileSidebar;
