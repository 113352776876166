import React, { useState } from 'react';
import apiConfig from '../../../config/apiConfig';

import { getCookie } from '../utility/cookieHelpers';

const API_UPDATE_CANDIDATE_ENDPOINT = `${apiConfig.baseURL}${apiConfig.endpoints.updateCandidate}`;
const API_TOKEN = apiConfig.token;

const EditCandidate = ({ candidate, onCancelEdit, onFetchCandidates }) => {
  const [editCandidateData, setEditCandidateData] = useState({ name: candidate.name, resume: candidate.resume });
  const [isSaving, setIsSaving] = useState(false);

  const handleSaveCandidate = async () => {
    setIsSaving(true);
    const payload = { pk: candidate.pk, ...editCandidateData };
    const token = getCookie('token');
    try {
      const response = await fetch(API_UPDATE_CANDIDATE_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'api-key': API_TOKEN,
        },
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        throw new Error('Failed to update candidate');
      }

      alert('Candidate details updated successfully!');
      await onFetchCandidates();
      onCancelEdit();
    } catch (error) {
      console.error('Error updating candidate:', error);
      alert('Failed to update candidate details. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="edit-candidate-box">
      <h2>Edit Candidate</h2>
      <label>
        Name:
        <input
          type="text"
          value={editCandidateData.name}
          onChange={(e) => setEditCandidateData({ ...editCandidateData, name: e.target.value })}
        />
      </label>
      <label>
        Resume:
        <textarea
          value={editCandidateData.resume}
          onChange={(e) => setEditCandidateData({ ...editCandidateData, resume: e.target.value })}
        />
      </label>
      <button onClick={handleSaveCandidate} disabled={isSaving}>
        {isSaving ? 'Saving...' : 'Save'}
      </button>
      <button onClick={onCancelEdit}>Cancel</button>
    </div>
  );
};

export default EditCandidate;
