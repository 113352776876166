import React from 'react';
import './PersonalInformationCard.css';

const PersonalInformationCard = ({ portfolio, topSkills, education, experience }) => {
  return (
    <div className="personal-information card">
      <h2>Personal Information</h2>
      <div className="personal-info-content">
        <div className="portfolio-and-education">
          <div>
          <p className='header'>Portfolio:</p> {portfolio ? <a href={portfolio.startsWith('http') ? portfolio : `https://${portfolio}`} target="_blank" rel="noopener noreferrer">{portfolio}</a> : 'Portfolio not available'}
          </div>
          <div className="education">
            <p className='header'>Education:</p>
            <ul>
              {education.length > 0 ? education.map((edu, index) => (
                <li key={index}>
                  <div className='detail-head'>{edu.Degree}, {edu.Institution}</div>
                  <div className='subtitle'>{edu.Year}</div>
                </li>
              )) : <li>Education details not available</li>}
            </ul>
          </div>
        </div>
        <div className="skills-and-experience">
          <div>
            <p className='header'>Top skills:</p> {topSkills.length > 0 ? topSkills.join(', ') : 'Skills not available'}
          </div>
          <div className="experience">
            <p className='header'>Experience:</p>
            <ul>
              {experience.length > 0 ? experience.map((exp, index) => (
                <li key={index}>
                  <div className='detail-head'>{exp.Title}, {exp.Company}</div>
                  <div className='subtitle'>{exp.Duration}</div>
                </li>
              )) : <li>Experience details not available</li>}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalInformationCard;
