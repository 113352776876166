// src/config/apiConfig.js
// src/config/apiConfig.js

const API_BASE_URL = 'https://svdev-avatar.kodelab.io';
const API_TOKEN = 'KODELAB_PROTO_KEY';
const COMPANY_PK = '01J6CA25FFAQ2SPD4FEXJ2WED2';
const COMPANY_NAME = 'kodelab.io';
const ADMIN_PASSWORD = 'y=gXDic-pS+:^w}';
const ADMIN_USERNAME = 'pickey';

const apiConfig = {

   baseURL: API_BASE_URL,
   token: API_TOKEN,
   company_pk: COMPANY_PK,
   company_name: COMPANY_NAME,

   endpoints: {
     getCandidates: '/pickey/db/get-candidates',
     getInterviewDetails: '/pickey/db/get-interview',
     getVacancyDetails: '/pickey/db/get-vacancy-details',
     saveCandidate: '/pickey/db/save-candidate',
     updateCandidate: '/pickey/db/update-candidate',
     deleteCandidate: '/pickey/db/delete-candidate',
     generateInterview: '/pickey/ai/generate-interview-from-jobdesc',
     generateJobDescription: '/pickey/ai/generate-job-description',
     saveVacancy: '/pickey/db/save-vacancy',
     updateVacancy: '/pickey/db/update-vacancy',
     toggleVacancyActive: '/pickey/db/toggle-vacancy',
     deleteVacancy: '/pickey/db/delete-vacancy',
     getVacancies: '/pickey/db/get-company-vacancies',
     validateEmail: '/pickey/otp/validate-email',
     generateOtp: '/pickey/otp/generate-otp',
     verifyOtp: '/pickey/otp/verify-otp',
     startInterview: '/pickey/ai/start-interview',
     getCvEval: '/pickey/db/get-cv-evaluation',
     getCvDownload: '/pickey/db/candidate-resume-url',
     getAuthToken: '/kopi/auth/token',
     getService: '/kiwi/crud/get-service',
     getProduct: '/kiwi/crud/get-product',
     createCompany: '/kiwi/crud/create-company',
     getCompanies: '/kiwi/crud/get-companies',
     updateCompany: '/kiwi/crud/update-company',
     addProduct: '/kiwi/crud/add-product',
     addService: '/kiwi/crud/add-service',
     removeService: '/kiwi/crud/remove-service',
     removeProduct: '/kiwi/crud/remove-product',
   },
};

export default apiConfig;