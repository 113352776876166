import React, { useState, useEffect, useRef } from 'react';
import CandidateEvaluation from './CandidateEvaluation';
import InterviewDetails from './InterviewDetails';
import apiConfig from '../../../config/apiConfig.js';
import PersonalInformationCard from './PersonalInformationCard';
import InterviewQuestions from '../vacancies/InterviewQuestions';
import back_arrow from '../../../assets/BsChevronLeft.png';

import {ReactComponent as LinkedInIcon } from '../../../assets/LinkedInIcon.svg';

import { getCookie } from '../utility/cookieHelpers';
import DownloadButton from '../utility/DownloadButton.js';

const API_GET_CV_EVALUATION_ENDPOINT = `${apiConfig.baseURL}${apiConfig.endpoints.getCvEval}`;
const API_GET_INTERVIEW_DETAILS_ENDPOINT = `${apiConfig.baseURL}${apiConfig.endpoints.getInterviewDetails}`;
const API_GET_VACANCY_DETAILS_ENDPOINT = `${apiConfig.baseURL}${apiConfig.endpoints.getVacancyDetails}`;
const API_GET_CV_DOWNLOAD = `${apiConfig.baseURL}${apiConfig.endpoints.getCvDownload}`;
const API_TOKEN = apiConfig.token;
const KODELAB_PK = apiConfig.company_pk;

const CandidateDetails = ({ candidate }) => {
  const [interviewDetails, setInterviewDetails] = useState([]);
  const [isLoadingInterviews, setIsLoadingInterviews] = useState(false);
  const [interviewFetchError, setInterviewFetchError] = useState(null);
  const [evaluationSummary, setEvaluationSummary] = useState('');
  const [candidateScores, setCandidateScores] = useState({});
  const [skillAverages, setSkillAverages] = useState({});
  const [showEvaluationReport, setShowEvaluationReport] = useState(false);
  const [selectedInterview, setSelectedInterview] = useState(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [downloadLink, setDownloadLink] = useState('');
  const [selectedChatHistory, setSelectedChatHistory] = useState(null);

  const popupRef = useRef(null);
  const historyPopupRef = useRef(null);

  useEffect(() => {
    if (candidate) {
      setShowEvaluationReport(false); // Reset to standard view on candidate change
      fetchEvaluationDetails(candidate);
      fetchInterviewDetails(candidate);
      fetchCandidateCvDownload(candidate);
    }
  }, [candidate]);

  useEffect(() => {
    if (selectedChatHistory) {
      document.addEventListener('mousedown', handleClickOutsideHistory);
    } else {
      document.removeEventListener('mousedown', handleClickOutsideHistory);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideHistory);  // Cleanup when the component unmounts
    };
  }, [selectedChatHistory]);

  useEffect(() => {
    if (isPopupVisible) {
      document.addEventListener('mousedown', handleClickOutsideVacancy);
    } else {
      document.removeEventListener('mousedown', handleClickOutsideVacancy);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideVacancy);  // Cleanup when the component unmounts
    };
  }, [isPopupVisible]);

  const handleClickOutsideHistory = (event) => {
    if (historyPopupRef.current && !historyPopupRef.current.contains(event.target)) {
      setSelectedChatHistory(null);
    }
  }

  const handleClickOutsideVacancy = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsPopupVisible(false);
    }
  };

  const fetchCandidateCvDownload = async (candidate) => {
    const token = getCookie('token');
    try {
      const payload = { pk: candidate.pk };
      const cvDownloadResponse = await fetch(API_GET_CV_DOWNLOAD, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'api-key': API_TOKEN
        },
        body: JSON.stringify(payload)
      });

      if (!cvDownloadResponse.ok) {
        throw new Error('Failed to fetch candidate cv download link');
      }

      const cvData = await cvDownloadResponse.json();
      setDownloadLink(cvData.url);

    } catch (error) {
      setDownloadLink('');
      console.log('Error fetching cv download link', error);
    }
  }

  const fetchEvaluationDetails = async (candidate) => {
    const token = getCookie('token');
    try {
      const payload = { candidate: candidate.pk, company: KODELAB_PK };
      const evaluationResponse = await fetch(API_GET_CV_EVALUATION_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'api-key': API_TOKEN
        },
        body: JSON.stringify(payload)
      });

      if (!evaluationResponse.ok) {
        throw new Error('Failed to fetch candidate evaluation details');
      }

      const evaluationData = await evaluationResponse.json();
      console.log('eval data', evaluationData)
      const skillsEvaluation = evaluationData.evaluation?.skills_evaluation || [];

      const categoryAverages = {};

      Object.entries(skillsEvaluation).forEach(([categoryKey, skills]) => {
        const total = Object.values(skills).reduce((acc, skill) => {
          return acc + parseInt(skill, 10);
        }, 0);
        const average = Math.round(total / Object.keys(skills).length);
        categoryAverages[categoryKey] = average;
      });


      const candidatesScores = {
        "CVScore": evaluationData.evaluation?.cv_overall_grade || 'N/A',
        "CompanyFitScore": evaluationData.evaluation?.company_fit_grade || 'N/A',
        "VacancyScore": evaluationData.evaluation?.vacancy_fit_grade || 'N/A'
      };

      setSkillAverages(categoryAverages);
      setEvaluationSummary(evaluationData.evaluation?.summary || 'N/A');
      setCandidateScores(candidatesScores);

    } catch (error) {
      console.error('Error fetching evaluation details:', error);
    }
  };

  const fetchInterviewDetails = async (candidate) => {
    setIsLoadingInterviews(true);
    setInterviewDetails([]);
    setInterviewFetchError(null);

    const fetchedInterviews = [];
    const token = getCookie('token');
    for (const interviewId of candidate.interviews) {
      try {
        const interviewResponse = await fetch(API_GET_INTERVIEW_DETAILS_ENDPOINT, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'api-key': API_TOKEN
          },
          body: JSON.stringify({ interview: interviewId })
        });

        if (!interviewResponse.ok) {
          throw new Error(`Failed to fetch interview details for ID ${interviewId}`);
        }

        const interviewData = await interviewResponse.json();
        const vacancyId = interviewData.interview.job_description;

        const vacancyResponse = await fetch(API_GET_VACANCY_DETAILS_ENDPOINT, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'api-key': API_TOKEN
          },
          body: JSON.stringify({ vacancy: vacancyId })
        });

        if (!vacancyResponse.ok) {
          console.warn(`Failed to fetch vacancy details for ID ${vacancyId} under interview ID ${interviewData.interview.pk}`);
          continue;
        }

        const vacancyData = await vacancyResponse.json();
        fetchedInterviews.push({
          interview: interviewData.interview,
          vacancy: vacancyData.vacancy
        });
      } catch (error) {
        console.error('Error fetching interview details:', error);
        setInterviewFetchError('Failed to fetch interview details. Please try again.');
      }
    }
    setInterviewDetails(fetchedInterviews);
    console.log('fetchedInterviews', fetchedInterviews);
    setIsLoadingInterviews(false);
  };

  const handleGoBack = () => {
    setShowEvaluationReport(false);
    setSelectedInterview(null);
  };

  const handleEvaluationReportClick = (interview) => {
    setSelectedInterview(interview);
    setShowEvaluationReport(true);
  };

  const handleOpenVacancyPopup = (job) => {
    setSelectedJob(job);
    setIsPopupVisible(true);
  };

  const handleCloseVacancyPopup = () => {
    setIsPopupVisible(false);
  };

  function formatDuration(seconds) {
    if (seconds === null) {
      return "Unavailable";
    }

    const totalSeconds = Math.floor(seconds); // Remove decimals
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const remainingSeconds = totalSeconds % 60;

    if (hours > 0) {
      return `${hours} hour${hours > 1 ? 's' : ''} ${minutes} minute${minutes > 1 ? 's' : ''}`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? 's' : ''}`;
    } else {
      return `${remainingSeconds} second${remainingSeconds > 1 ? 's' : ''}`;
    }
  }

  const renderEvaluationDetails = (interview) => {
    if (!interview || !interview.evaluation) return <div>No evaluation details available.</div>;

    return interview?.evaluation?.questions_scores?.map((evalDetail, index) => (
      <div key={index} className="evaluation-item">
        <h4>Question {evalDetail?.question_number || 'N/A'}: {evalDetail?.question || 'Question not available'}</h4>
        <p><strong>Candidate answer:</strong> {evalDetail?.answer || 'Answer not available'}</p>
        <p><strong>Score:</strong> {evalDetail?.score !== undefined ? evalDetail.score : 'N/A'}</p>
        <p><strong>Observation:</strong> {evalDetail?.explanatoon || 'Observation not available'}</p>
        <p><strong>Agent answer:</strong> {evalDetail?.agent_answer || 'Agent answer not available'}</p>
        <p><strong>AI prob:</strong> {evalDetail?.aiprob !== undefined ? evalDetail.aiprob : 'N/A'}</p>
      </div>
    )) || <p>No evaluation details available.</p>;

  };

  const contactDetails = [
    candidate?.email || '',
    candidate?.phone || '',
    candidate?.address || ''
  ].filter(detail => Boolean(detail)).join(' | ');

  return candidate ? (
    <div className="candidate-details">
      <div className="candidate-details-header">
        <div>
          <h1 className="candidate-name-title" style={{ fontSize: '36px' }}>{candidate.name}</h1>
          <p className="candidate-position-subtitle">
            {candidate.Work_Experience?.[0]?.Title || 'Title not available'} at {candidate.Work_Experience?.[0]?.Company || 'Company not available'}
          </p>
        </div>
        <div className="candidate-contacts-container">
          <div className="candidate-contacts">
            <span>{candidate.Personal_Information.linkedin && (
              <a href={candidate.Personal_Information.linkedin.startsWith('http') ? candidate.Personal_Information.linkedin : `https://${candidate.Personal_Information.linkedin}`} target="_blank" rel="noopener noreferrer">
                <LinkedInIcon alt="LinkedIn" className="candidate-contact-icon" />
              </a>
            )}</span>
            <DownloadButton resumeFile={candidate.resume_file}
              resumeFilename={candidate.resume_filename}
              resumeFiletype={candidate.resume_filetype} url={downloadLink} />
          </div>
          <div className="candidate-location">
            <p>{contactDetails}</p>
          </div>
        </div>
      </div>


      {showEvaluationReport && selectedInterview ? (
        <div>
          <div className="evaluation-report">
            <div className="vacancy-eval-back-btn">
              <img src={back_arrow} className="back-arrow-icon" alt="Back" />
              <p onClick={handleGoBack}>Go back</p>
            </div>
            <h4 style={{ fontSize: '22px' }}>{selectedInterview.jobTitle}</h4>
            <CandidateEvaluation candidateScores={candidateScores} skillAverages={skillAverages} evaluationSummary={evaluationSummary} vacancy={true} />
            <div className='evaluation-headers'>
              <p><strong>Interview Duration:</strong> {formatDuration(selectedInterview.interview.elapsed)}</p>
              <p
                className="evaluation-chathistory"
                onClick={() => setSelectedChatHistory(selectedInterview.interview.interview)}
              >
                Chat History
              </p>

              {selectedChatHistory && (
                <div className="popup-overlay">
                  <div className="popup-content" ref={historyPopupRef}>
                    <button className="popup-close" onClick={() => setSelectedChatHistory(null)}>×</button>
                    <h2 className="popup-title">Chat History</h2>
                    <div className="popup-content-text chat-messages">
                      {selectedChatHistory.map((message, index) => (
                        <div key={index} className={`message ${message.role === 'assistant' ? 'assistant' : 'user'}`}>
                          <p>{message.content}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}


            </div>
            <div>{renderEvaluationDetails(selectedInterview.interview)}</div>
          </div>
        </div>
      ) : (
        <div>
          <div className="candidate-evaluation card">
            <CandidateEvaluation candidateScores={candidateScores} skillAverages={skillAverages} evaluationSummary={evaluationSummary} vacancy={false} />
          </div>
          <PersonalInformationCard
            portfolio={candidate.Personal_Information?.portfolio || candidate.Personal_Information?.website}
            topSkills={candidate.others?.top_skills || []}
            education={candidate.Education || []}
            experience={candidate.Work_Experience || []}
          />

          <div className="candidate-interview-details inviscard">
            {isPopupVisible && (
              <div className="popup-overlay">
                <div className="popup-content" ref={popupRef}>
                  <button className="popup-close" onClick={handleCloseVacancyPopup}>×</button>
                  <h2 className="popup-title">{selectedJob.vacancy.job_title}</h2>
                  <div className="popup-content-text">
                    <p>{selectedJob.vacancy.job_description}</p>
                    <InterviewQuestions questions={selectedJob.vacancy.questions.questions} />
                  </div>
                </div>
              </div>
            )}
            <h2>Interviews</h2>
            <InterviewDetails
              interviewDetails={interviewDetails}
              isLoadingInterviews={isLoadingInterviews}
              interviewFetchError={interviewFetchError}
              onEvaluationReportClick={handleEvaluationReportClick}
              handleOpenVacancyPopup={handleOpenVacancyPopup}
            />
          </div>
        </div>
      )}
    </div>
  ) : (
    //You can use this to show a message when the candidates are loading
    <div className="no-candidate-selected"></div>
  );
};

export default CandidateDetails;
