import React, { useState } from 'react';
import JobDescriptionStep from './JobDescriptionStep';
import InterviewQuestionStep from './InterviewQuestionStep';
import ReviewStep from './ReviewStep';
import SuccessStep from './SuccessStep';
import './NewVacancy.css';

import apiConfig from '../../../../config/apiConfig';
import { getCookie } from '../../utility/cookieHelpers'


const API_SAVE_VACANCY_ENDPOINT = `${apiConfig.baseURL}${apiConfig.endpoints.saveVacancy}`;
const API_TOKEN = apiConfig.token;

const NewVacancy = ({ step, setStep }) => { // Use step and setStep from props
  const [jobTitle, setJobTitle] = useState('');
  const [salaryRange, setSalaryRange] = useState('');
  const [experience, setExperience] = useState('');
  const [location, setLocation] = useState('');
  const [jobDescription, setJobDescription] = useState('');
  const [openEndedQuestions, setOpenEndedQuestions] = useState('');
  const [multipleChoiceQuestions, setMultipleChoiceQuestions] = useState('');
  const [cvBasedQuestions, setCvBasedQuestions] = useState('');
  const [psychologicalQuestions, setPsychologicalQuestions] = useState('');
  const [showWarning, setShowWarning] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [interviewQuestions, setInterviewQuestions] = useState([]);
  const [rawInterviewQuestions, setRawInterviewQuestions] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [vacancyId, setVacancyId] = useState(null);
  const [cvQuestions, setCvQuestions] = useState('');
  const [pQuestions, setPQuestions] = useState('');
  const [nQuestions, setNQuestions] = useState('');
  const [kQuestions, setKQuestions] = useState('');
  const [selectedCompanyPk, setSelectedCompanyPk] = useState('');

  const handleNextStep = async () => {
    if (step === 3) {
      // Before moving to success step, save the vacancy
      setIsSaving(true);
      const payload = {
        job_title: jobTitle,
        job_description: jobDescription,
        questions: JSON.stringify(rawInterviewQuestions.questions),
        cv_questions: cvQuestions,
        p_questions: pQuestions,
        n_questions: nQuestions,
        k_questions: kQuestions,
        company: selectedCompanyPk,
      };
      console.log(payload);
      try {
        const token = getCookie('token');
        const response = await fetch(API_SAVE_VACANCY_ENDPOINT, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'api-key': API_TOKEN
          },
          body: JSON.stringify(payload)
        });

        const data = await response.json();
        console.log('data!', data);

        if (!response.ok) {
          throw new Error('Failed to save vacancy');
        }

        if (!data.success) {
          console.log(data.response);
          throw new Error('Failed to save vacancy');
        }
        console.log('Setting vacancy ID', data.response);
        setVacancyId(data.response); // Set the vacancy ID
        setIsSaving(false);
        setStep(step + 1);
      } catch (error) {
        console.error('Error saving vacancy:', error);
        setIsSaving(false);
        alert('Failed to save vacancy. Please try again.');
      }
    } else if (step === 2) {  // Capture question counts when moving from step 2
      setCvQuestions(cvBasedQuestions);
      setPQuestions(psychologicalQuestions);
      setNQuestions(openEndedQuestions);
      setKQuestions(multipleChoiceQuestions);

      setStep(step + 1); // Proceed to the next step
    } else {
      setStep(step + 1);
    }
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const handleEditStep = (section) => {
    switch (section) {
      case 'jobTitle':
      case 'salaryRange': // Handle edit for salary range
      case 'jobDescription':
        setStep(1);
        break;
      case 'interviewQuestions':
        setStep(2);
        break;
      default:
        break;
    }
  };

  return (
    <div className="new-vacancy-page">
        {isSaving ? (
          <div className="loading-popup">
            <div className="loading-spinner">
              <div className="spinner"></div>
              <p>Saving vacancy, please wait...</p>
            </div>
          </div>
        ) : (
          <>
            {step === 1 && <JobDescriptionStep
              jobTitle={jobTitle}
              setJobTitle={setJobTitle}
              salaryRange={salaryRange}
              setSalaryRange={setSalaryRange}
              experience={experience}
              setExperience={setExperience}
              location={location}
              setLocation={setLocation}
              jobDescription={jobDescription}
              setJobDescription={setJobDescription}
              showWarning={showWarning}
              isGenerating={isGenerating}
              setIsGenerating={setIsGenerating}
              setShowWarning={setShowWarning}
              handleNextStep={handleNextStep}
              selectedCompanyPk={selectedCompanyPk} 
              setSelectedCompanyPk={setSelectedCompanyPk}
            />}
            {step === 2 && <InterviewQuestionStep
              jobDescription={jobDescription}
              openEndedQuestions={openEndedQuestions}
              setOpenEndedQuestions={setOpenEndedQuestions}
              multipleChoiceQuestions={multipleChoiceQuestions}
              setMultipleChoiceQuestions={setMultipleChoiceQuestions}
              cvBasedQuestions={cvBasedQuestions}
              setCvBasedQuestions={setCvBasedQuestions}
              psychologicalQuestions={psychologicalQuestions}
              setPsychologicalQuestions={setPsychologicalQuestions}
              handleNextStep={handleNextStep}
              handlePreviousStep={handlePreviousStep}
              setInterviewQuestions={setInterviewQuestions}
              interviewQuestions={interviewQuestions}
              jobTitle={jobTitle}
              setRawInterviewQuestions={setRawInterviewQuestions}
            />}
            {step === 3 && (
              <ReviewStep
                jobTitle={jobTitle}
                setJobTitle={setJobTitle}
                salaryRange={salaryRange}
                setSalaryRange={setSalaryRange}
                jobDescription={jobDescription}
                interviewQuestions={interviewQuestions}
                handleEditStep={handleEditStep}
                handleNextStep={handleNextStep}
                handlePreviousStep={handlePreviousStep}
              />
            )}
            {step === 4 && <SuccessStep vacancyId={vacancyId} />}
          </>
        )}
    </div>
  );
};

export default NewVacancy;
