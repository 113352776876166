import React from 'react';
import './VacancyDetails.css';
import InterviewQuestions from './InterviewQuestions';

const VacancyDetails = ({ selectedVacancy, vacancyQuestions, copyLinkToClipboard }) => {
  return (
    <div className="vacancy-details">
      <div className="vacancy-header">
        <div className="header-content">
          <div className="header-content-horizontal"> 
          <h2>{selectedVacancy.job_title}</h2>
          <span className={`status-badge ${selectedVacancy.active ? 'active' : 'inactive'}`}>
            {selectedVacancy.active ? 'Active' : 'Inactive'}
          </span>
          </div>
          <div className="vacancy-info">
            <p>{new Date(selectedVacancy.opened * 1000).toLocaleDateString()}</p>
            {selectedVacancy.closed && (
                <p>{new Date(selectedVacancy.closed * 1000).toLocaleDateString()}</p>
            )}
          </div>

        </div>
        <div className="header-actions">
          <button
            onClick={() => copyLinkToClipboard(selectedVacancy.pk)}
            className="copy-link-btn">
            Copy interview link
          </button>
        </div>
      </div>
      <div className="vacancy-description card">
      <h3>Job Description</h3>
      {typeof selectedVacancy.job_description === 'string' && (
        <pre>{selectedVacancy.job_description}</pre>
      )}
      </div>
      <div className="vacancy-questions card">
      <InterviewQuestions questions={vacancyQuestions} />
      </div>
    </div>
  );
};

export default VacancyDetails;
