import React from 'react';
import file_icon from '../../../assets/BsFileEarmarkText.png';
import { ReactComponent as FileIcon } from '../../../assets/FileIcon.svg';

function DownloadButton({ resumeFile, resumeFilename, resumeFiletype, url }) {
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = url;
    link.download = resumeFilename;
    link.click();
  };

  return (
    <button onClick={handleDownload} style={{ border: 'none', background: 'none', cursor: 'pointer' }}>
      <FileIcon alt="Download" />
    </button>
  ); 
}

export default DownloadButton;
