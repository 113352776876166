import React from 'react';
import ProfileDetails from './ProfileDetails';
import ProfileSidebar from './ProfileSidebar';
import './Profile.css';

const Profile = () => {
  return (
    <div className="profile-container">
      <ProfileSidebar />
      <ProfileDetails />
    </div>
  );
};

export default Profile;
