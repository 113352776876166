import React from 'react';
import './EditVacancyForm.css';
import InterviewQuestions from './InterviewQuestions';

const EditVacancyForm = ({
  editedVacancy,
  handleEditChange,
  handleSaveEdit,
  handleCancelEdit,
  isSaving,
  vacancyQuestions,
}) => {
  return (
    <div className="vacancy-details">
      <form className="edit-vacancy-form card">
        <label>
          Job Title:
          <input
            type="text"
            name="job_title"
            value={editedVacancy.job_title}
            onChange={handleEditChange}
          />
        </label>
        <label>
          Company:
          <input
            type="text"
            name="company"
            value={editedVacancy.company}
            onChange={handleEditChange}
          />
        </label>
        <label>
          Active:
          <select
            name="active"
            value={editedVacancy.active}
            onChange={handleEditChange}
          >
            <option value={true}>Yes</option>
            <option value={false}>No</option>
          </select>
        </label>
        <label>
          Description:
          <textarea
            name="description"
            value={editedVacancy.description}
            onChange={handleEditChange}
          />
        </label>
        <InterviewQuestions questions={vacancyQuestions} />
        <div className="buttons">
          <button type="button" onClick={handleSaveEdit} disabled={isSaving}>
            {isSaving ? 'Saving...' : 'Save'}
          </button>
          <button type="button" onClick={handleCancelEdit}>Cancel</button>
        </div>
      </form>
    </div>
  );
};

export default EditVacancyForm;
