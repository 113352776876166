import React from 'react';
import { CircularProgress } from '@mui/material';
import './Modals.css';

const OtpVerificationModal = ({ otp, setOtp, handleVerifyOtp, loadingOtp }) => (
  <div className="modal">
    <div className="modal-content">
      <h2>OTP Verification</h2>
      <p>Please enter the OTP sent to your email:</p>
      <div className="modal-otp-container">
        <input
          type="number"
          inputMode="numeric"
          placeholder="Enter OTP"
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
          className="modal-otp-input"
          disabled={loadingOtp}
          pattern="[0-9]*" 
        />

        <button
          onClick={handleVerifyOtp}
          className="modal-otp-button"
          disabled={loadingOtp}
        >
          {loadingOtp ? <CircularProgress size={14} /> : 'Verify OTP'}
        </button>
      </div>
    </div>
  </div>
);

export default OtpVerificationModal;
