import React, { useState, useRef } from 'react';
import './EditCompanyForm.css'; // Reuse the same styles as EditCompanyForm for now
import industryOptions from './data/industryOptions';
import CustomSnackbar from '../utility/CustomSnackBar';
import { CircularProgress } from '@mui/material';

const NewCompanyForm = ({ onCreateCompany }) => {
  const [formState, setFormState] = useState({
    name: '',
    description: '',
    vision: '',
    mission: '',
    company_culture: '',
    industry: '',
    founded: '',
    num_employees: '',
    headquarters: '',
    website: '',
    email: '',
    phone: '',
  });

  const [values, setValues] = useState({}); // Updated to object format
  const [newValue, setNewValue] = useState({ name: '', tagline: '' });
  const [isExpanded, setIsExpanded] = useState(false);
  const [productDetails, setProductDetails] = useState({ name: '', description: '' });
  const [serviceDetails, setServiceDetails] = useState({ name: '', description: '' });
  const [achievements, setAchievements] = useState('');
  const [collaborations, setCollaborations] = useState('');

  const [products, setProducts] = useState([]); // State for products list
  const [services, setServices] = useState([]); // State for services list

  const [otherAddresses, setOtherAddresses] = useState([]); // State for additional locations
  const [newAddress, setNewAddress] = useState(''); // State for new additional location input

  const [socialMedias, setSocialMedias] = useState({}); // Updated to object format
  const [newSocialMedia, setNewSocialMedia] = useState({ platform: '', url: '' });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');


  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const toggleSection = () => {
    if (isExpanded) {
      setProductDetails({ name: '', description: '' });
      setServiceDetails({ name: '', description: '' });
      setAchievements('');
      setCollaborations('');
    }
    setIsExpanded(!isExpanded);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: name === 'num_employees' ? parseInt(value) : value, // Convert num_employees to integer
    });
  };

  const handleValueChange = (e) => {
    const { name, value } = e.target;
    setNewValue({
      ...newValue,
      [name]: value,
    });
  };

  const handleProductChange = (e) => {
    const { name, value } = e.target;
    setProductDetails({
      ...productDetails,
      [name]: value,
    });
  };

  const handleServiceChange = (e) => {
    const { name, value } = e.target;
    setServiceDetails({
      ...serviceDetails,
      [name]: value,
    });
  };

  const handleSocialMediaChange = (e) => {
    const { name, value } = e.target;
    setNewSocialMedia({
      ...newSocialMedia,
      [name]: value,
    });
  };

  const addValue = () => {
    if (newValue.name && newValue.tagline) {
      setValues({ ...values, [newValue.name]: newValue.tagline }); // Updated to add as key-value pairs
      setNewValue({ name: '', tagline: '' }); // Reset inputs after adding
    }
  };

  const removeValue = (name) => {
    const updatedValues = { ...values };
    delete updatedValues[name];
    setValues(updatedValues);
  };

  const addProduct = () => {
    if (productDetails.name && productDetails.description) {
      setProducts([...products, productDetails]);
      setProductDetails({ name: '', description: '' }); // Reset inputs after adding
    }
  };

  const removeProduct = (index) => {
    const updatedProducts = products.filter((_, i) => i !== index);
    setProducts(updatedProducts);
  };

  const addService = () => {
    if (serviceDetails.name && serviceDetails.description) {
      setServices([...services, serviceDetails]);
      setServiceDetails({ name: '', description: '' }); // Reset inputs after adding
    }
  };

  const removeService = (index) => {
    const updatedServices = services.filter((_, i) => i !== index);
    setServices(updatedServices);
  };

  const addAddress = () => {
    if (newAddress) {
      setOtherAddresses([...otherAddresses, newAddress]);
      setNewAddress(''); // Reset input after adding
    }
  };

  const removeAddress = (index) => {
    const updatedAddresses = otherAddresses.filter((_, i) => i !== index);
    setOtherAddresses(updatedAddresses);
  };

  const addSocialMedia = () => {
    if (newSocialMedia.platform && newSocialMedia.url) {
      setSocialMedias({ ...socialMedias, [newSocialMedia.platform]: newSocialMedia.url }); // Updated to object format
      setNewSocialMedia({ platform: '', url: '' }); // Reset inputs after adding
    }
  };

  const removeSocialMedia = (platform) => {
    const updatedSocialMedias = { ...socialMedias };
    delete updatedSocialMedias[platform];
    setSocialMedias(updatedSocialMedias);
  };

  const ensureURLScheme = (url) => {
    if (!url) return null; // Return null if the URL is empty or not provided
    return url.startsWith("http://") || url.startsWith("https://")
      ? url
      : `https://${url}`; // Add 'https://' if no scheme is present
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    

    const payload = {
      name: formState.name.trim(),
      vision: formState.vision?.trim() || null,
      mission: formState.mission?.trim() || null,
      description: formState.description?.trim() || null,
      company_culture: formState.company_culture?.trim() || null,
      values: Object.fromEntries(
        Object.entries(values).filter(
          ([key, value]) => typeof key === 'string' && typeof value === 'string'
        )
      ),
      industry: formState.industry?.trim() || null,
      founded: formState.founded?.trim() || null,
      num_employees: Number.isInteger(formState.num_employees)
        ? formState.num_employees
        : null,
      headquarters: formState.headquarters?.trim() || null,
      website: ensureURLScheme(formState.website?.trim()),
      email: formState.email?.trim() || null,
      phone: formState.phone?.trim() || null,
      other_addresses: otherAddresses.filter((address) => address.trim() !== ''),
      social_media: Object.fromEntries(
        Object.entries(socialMedias)
          .filter(
            ([platform, url]) => typeof platform === 'string' && url.trim() !== ''
          )
          .map(([platform, url]) => [platform, ensureURLScheme(url)])
      ),
      products: products.filter(
        (product) => product.name.trim() && product.description.trim()
      ),
      services: services.filter(
        (service) => service.name.trim() && service.description.trim()
      ),
    };

    // Basic input validation for required fields
    const requiredFields = [
      'name',
      'industry',
      'mission',
      'vision',
      'description',
    ]

    let newErrors = {};
    requiredFields.forEach((field) => {
      if (!formState[field] || formState[field].trim() === '') {
        newErrors[field] = 'This field is required.';
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setSnackbarMessage('Fill out required fields.');
      setSnackbarOpen(true);
      return;
    } else {
      setErrors({});
    }

    setIsLoading(true);

    try {
      await onCreateCompany(payload);
    } catch (error) {
      console.error('Error creating company:', error);
      setSnackbarMessage('There was an error creating the company');
      setSnackbarOpen(true);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  return (
    <div className="company-details">
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        backgroundColor="#f8d7da" 
        textColor="#721c24"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3000}
      />
      <div className="company-overview">
        <div className="company-details-header">
          <h3>Create Company</h3>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>Company name</label>
            <input
              className={`company-overview-enabled ${errors.name ? 'input-error' : ''}`}
              type="text"
              name="name"
              value={formState.name}
              onChange={handleChange}
              placeholder="Enter name..."
            />
            {errors.name && <span className="error-message">{errors.name}</span>}
          </div>
          <div className="form-group">
            <label>Industry</label>
            <select
              name="industry"
              value={formState.industry || ''}
              onChange={handleChange}
              className={`company-overview-enabled ${errors.industry ? 'input-error' : ''}`}
            >
              <option value="">Select Industry</option>
              {industryOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            {errors.industry && <span className="error-message">{errors.industry}</span>}
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>Founded year</label>
            <input
              type="date"
              name="founded"
              value={formState.founded}
              onChange={handleChange}
              placeholder="Enter founded year..."
            />
          </div>
          <div className="form-group">
            <label>Number of employees</label>
            <select name="num_employees" value={formState.num_employees} onChange={handleChange}>
              <option value="">Select number of employees</option>
              <option value="1">1-10</option>
              <option value="11">11-50</option>
              <option value="51">51-200</option>
              <option value="201">201-500</option>
              <option value="501">501-1,000</option>
              <option value="1001">1,001-5,000</option>
              <option value="5001">5,001-10,000</option>
              <option value="10001">10,001+</option>
            </select>
          </div>
        </div>

        <div className="form-group">
          <label>Logo</label>
          <div className="logo-container">
            <img src="" alt="Company Logo" className="company-logo" />
          </div>
        </div>
        <div className="form-group">
          <label>Company mission</label>
          <textarea
            name="mission"
            value={formState.mission}
            onChange={handleChange}
            placeholder="Enter mission..."
            className={`editable-textarea ${errors.mission ? 'input-error' : ''}`}
          ></textarea>
          {errors.mission && <span className="error-message">{errors.mission}</span>}
        </div>
        <div className="form-group">
          <label>Company vision</label>
          <textarea
            name="vision"
            value={formState.vision}
            onChange={handleChange}
            placeholder="Enter vision..."
            className={`editable-textarea ${errors.vision ? 'input-error' : ''}`}
          ></textarea>
          {errors.vision && <span className="error-message">{errors.vision}</span>}
        </div>
        <div className="form-group">
          <label>Company description</label>
          <textarea
            name="description"
            value={formState.description}
            onChange={handleChange}
            placeholder="Enter description..."
            className={`editable-textarea ${errors.description ? 'input-error' : ''}`}
          />
          {errors.description && <span className="error-message">{errors.description}</span>}
        </div>
      </div>

      <div className="company-values-and-culture card">
        <div className="company-details-header">
          <h3>Values & Culture</h3>
        </div>

        {/* Dynamic Values Input */}
        <div className="form-group">
          <label>Value Name</label>
          <input
            type="text"
            name="name"
            value={newValue.name}
            onChange={handleValueChange}
            placeholder="Enter value name..."
          />
        </div>
        <div className="form-group">
          <label>Value Tagline</label>
          <input
            type="text"
            name="tagline"
            value={newValue.tagline}
            onChange={handleValueChange}
            placeholder="Enter tagline..."
          />
        </div>
        <button type="button" onClick={addValue} className="toggle-button">
          Add Value
        </button>

        {/* List of Added Values */}
        <ul className="value-list">
          {Object.entries(values).map(([key, tagline]) => (
            <li key={key} className="value-item">
              <strong>{key}</strong>: {tagline}
              <button type="button" onClick={() => removeValue(key)}>
                Remove
              </button>
            </li>
          ))}
        </ul>

        <div className="form-group">
          <label>Culture</label>
          <textarea
            name="company_culture"
            value={formState.company_culture}
            onChange={handleChange}
            placeholder="Enter culture..."
            className="editable-textarea"
          ></textarea>
        </div>
        <div className="form-group">
          <label>Employee benefits & perks</label>
          <textarea
            placeholder="Unavailable"
            className="editable-textarea"
            readOnly
          ></textarea>
        </div>
      </div>

      <div className="company-business-portfolio card">
        <div className="company-details-header">
          <h3>
            Business Portfolio
            <button onClick={toggleSection} className="toggle-button">
              {isExpanded ? 'Remove' : 'Include'}
            </button>
          </h3>
        </div>

        {isExpanded && (
          <>
            {/* Products Section */}
            <div className="portfolio-section">
              <h4>Products</h4>
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  value={productDetails.name}
                  onChange={handleProductChange}
                  placeholder="Enter product name..."
                />
              </div>
              <div className="form-group">
                <textarea
                  name="description"
                  placeholder="Enter product details..."
                  className="editable-textarea"
                  value={productDetails.description}
                  onChange={handleProductChange}
                ></textarea>
              </div>
              <button type="button" onClick={addProduct} className="toggle-button">
                Add Product
              </button>

              {/* List of Added Products */}
              <ul className="product-list">
                {products.map((product, index) => (
                  <li key={index} className="product-item">
                    <strong>{product.name}</strong>: {product.description}
                    <button type="button" onClick={() => removeProduct(index)}>
                      Remove
                    </button>
                  </li>
                ))}
              </ul>
            </div>

            {/* Services Section */}
            <div className="portfolio-section">
              <h4>Services</h4>
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  value={serviceDetails.name}
                  onChange={handleServiceChange}
                  placeholder="Enter service name..."
                />
              </div>
              <div className="form-group">
                <textarea
                  name="description"
                  placeholder="Enter service details..."
                  className="editable-textarea"
                  value={serviceDetails.description}
                  onChange={handleServiceChange}
                ></textarea>
              </div>
              <button type="button" onClick={addService} className="toggle-button">
                Add Service
              </button>

              {/* List of Added Services */}
              <ul className="service-list">
                {services.map((service, index) => (
                  <li key={index} className="service-item">
                    <strong>{service.name}</strong>: {service.description}
                    <button type="button" onClick={() => removeService(index)}>
                      Remove
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </>
        )}
      </div>

      <div className="company-contact card">
        <div className="company-details-header">
          <h3>Contact</h3>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>Company email</label>
            <input
              type="email"
              name="email"
              value={formState.email}
              onChange={handleChange}
              placeholder="Enter email..."
              required
            />
          </div>

          <div className="form-group">
            <label>Company website</label>
            <input
              type="url"
              name="website"
              value={formState.website}
              onChange={handleChange}
              placeholder="Enter website..."
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>Primary Location</label>
            <input
              type="text"
              name="headquarters"
              value={formState.headquarters}
              onChange={handleChange}
              placeholder="Enter primary location..."
            />
          </div>
          {/* Dynamic Additional Locations Input */}
          <div className="form-group">
            <label>Additional Location</label>
            <input
              type="text"
              value={newAddress}
              onChange={(e) => setNewAddress(e.target.value)}
              placeholder="Enter additional location..."
            />
            <button type="button" onClick={addAddress} className="toggle-button">
              Add Location
            </button>
          </div>
        </div>
        {/* List of Added Additional Locations */}
        <ul className="additional-location-list">
          {otherAddresses.map((address, index) => (
            <li key={index} className="additional-location-item">
              {address}
              <button type="button" onClick={() => removeAddress(index)}>
                Remove
              </button>
            </li>
          ))}
        </ul>

        {/* Social Media Section */}
        <div className="company-details-header">
          <h3>Social media</h3>
        </div>
        <div className="form-row">
          <div className="form-group">
            <select
              name="platform"
              value={newSocialMedia.platform}
              onChange={handleSocialMediaChange}
            >
              <option value="">Select Platform</option>
              <option value="Discord">Discord</option>
              <option value="Facebook">Facebook</option>
              <option value="Instagram">Instagram</option>
              <option value="LinkedIn">LinkedIn</option>
              <option value="Pinterest">Pinterest</option>
              <option value="Reddit">Reddit</option>
              <option value="Telegram">Telegram</option>
              <option value="TikTok">TikTok</option>
              <option value="Twitter">Twitter</option>
              <option value="YouTube">YouTube</option>
            </select>
          </div>
          <div className="form-group">
            <input
              type="url"
              name="url"
              value={newSocialMedia.url}
              onChange={handleSocialMediaChange}
              placeholder="Enter URL..."
            />
          </div>
          <button type="button" onClick={addSocialMedia} className="toggle-button">
            Add Social Media
          </button>
        </div>

        {/* List of Added Social Media */}
        <ul className="social-media-list">
          {Object.entries(socialMedias).map(([platform, url]) => (
            <li key={platform} className="social-media-item">
              <strong>{platform}</strong>: {url}
              <button type="button" onClick={() => removeSocialMedia(platform)}>
                Remove
              </button>
            </li>
          ))}
        </ul>
      </div>

      {/* Submit Button */}
      <button type="submit" onClick={handleSubmit}>
        Create Company
      </button>

      {isLoading && (
        <div className="loading-overlay">
          <CircularProgress />
        </div>
      )}

    </div>
  );
};

export default NewCompanyForm;
