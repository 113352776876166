import { React, useState } from 'react';
import './CompanyList.css';
import { ReactComponent as SearchIcon } from '../../../assets/SearchIcon.svg';

const CompanyList = ({
  companies,
  isLoadingCompanies,
  fetchError,
  selectedCompany,
  onCompanyClick,
  onEditCompany,
  onCreateNewCompany,
}) => {

  const [searchQuery, setSearchQuery] = useState('');

  return (
    <aside className="companies-sidebar">
      <div className="companies-header">
        <div className="companies-header-title">
          <h3>Companies</h3>
          <button className="create-new-btn" onClick={onCreateNewCompany}>+ Create new</button>
        </div>
        <div className="search-bar-container">
          <SearchIcon className="search-icon" />
          <input
            type="text"
            className="search-bar"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      </div>
      <ul className="company-list">
        {isLoadingCompanies ? (
          <li className="no-companies-message">Loading companies...</li>
        ) : fetchError ? (
          <li className="no-companies-message">{fetchError}</li>
        ) : companies.length === 0 ? (
          <li className="no-companies-message">No companies found</li>
        ) : (
          companies.filter((company) =>
            company.company.name.toLowerCase().includes(searchQuery.toLowerCase()))
            .map((company) => (
              <li
                key={company.company.pk}
                className={`company-item ${selectedCompany && selectedCompany.pk === company.company.pk ? 'selected' : ''}`}
                onClick={() => onCompanyClick(company.company)}
              >
                <div className="company-content">
                  <h3 className="company-name">{company.company.name}</h3>
                  <p className="company-industry">{company.company.industry}</p>
                </div>
              </li>
            ))
        )}
      </ul>
    </aside>
  );
};

export default CompanyList;
