import React, { useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import './CandidateList.css';
import { ReactComponent as SearchIcon } from '../../../assets/SearchIcon.svg';
const CandidateList = ({ candidates, isLoadingCandidates, fetchError, selectedCandidate, onCandidateClick, onEditCandidate, onDeleteCandidate }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentCandidate, setCurrentCandidate] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const handleMenuOpen = (event, candidate) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setCurrentCandidate(candidate);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setCurrentCandidate(null);
  };

  return (
    <aside className="candidates-sidebar">
      <div className="candidates-sidebar-header">
        <h3>Candidates</h3>
        <div className="search-bar-container">
          <SearchIcon className="search-icon" />
          <input
            type="text"
            className="search-bar"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      </div>
      <ul className="candidate-list">
        {isLoadingCandidates ? (
          <li className="no-candidates-message">Loading candidates...</li>
        ) : fetchError ? (
          <li className="no-candidates-message">{fetchError}</li>
        ) : candidates.length === 0 ? (
          <li className="no-candidates-message">No candidates found</li>
        ) : (
          candidates.filter((candidate) =>
            candidate.name.toLowerCase().includes(searchQuery.toLowerCase()))
            .map((candidate) => (
              <li
                key={candidate.pk}
                className={`candidate-item ${selectedCandidate && selectedCandidate.pk === candidate.pk ? 'selected' : ''}`}
                onClick={() => onCandidateClick(candidate)}
              >
                <div className="candidate-content">
                  <h3 className="candidate-name">{candidate.name}</h3>
                </div>
                <div className="dropdown-container">
                  <button
                    className="dropdown-btn"
                    onClick={(e) => handleMenuOpen(e, candidate)}
                  >
                    ⋮
                  </button>
                </div>
              </li>
            ))
        )}
      </ul>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => {
          onEditCandidate(currentCandidate);
          handleMenuClose();
        }}>
          Edit details
        </MenuItem>
        <MenuItem
          onClick={() => {
            onDeleteCandidate(currentCandidate.pk);
            handleMenuClose();
          }}
          style={{ color: 'red' }}
        >
          Delete
        </MenuItem>
      </Menu>
    </aside>
  );
};

export default CandidateList;
