import React from 'react';
import './Interview.css';

const ErrorModal = ({ title, message }) => (
  <div className="modal">
    <div className="modal-content">
      <h2>{title}</h2>
      <p>{message}</p>
    </div>
  </div>
);

export default ErrorModal;
