import React, { useState } from 'react';
import './InterviewQuestionStep.css';
import apiConfig from '../../../../config/apiConfig';
import { getCookie } from '../../utility/cookieHelpers';

import { ReactComponent as GenerateIcon } from '../../../../assets/AiIcon.svg';
import { ReactComponent as GripperIcon } from '../../../../assets/BsGripVertical.svg';

import TextField from '@mui/material/TextField';

const API_ENDPOINT = `${apiConfig.baseURL}${apiConfig.endpoints.generateInterview}`;
const API_TOKEN = apiConfig.token;

const InterviewQuestionStep = ({
  jobTitle,
  jobDescription,
  openEndedQuestions, setOpenEndedQuestions,
  multipleChoiceQuestions, setMultipleChoiceQuestions,
  psychologicalQuestions, setPsychologicalQuestions,
  cvBasedQuestions, setCvBasedQuestions,
  handleNextStep,
  handlePreviousStep,
  setInterviewQuestions, interviewQuestions,
  setRawInterviewQuestions,

}) => {
  const [error, setError] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);

  const handleQuestionChange = (setter) => (e) => {
    const value = e.target.value;
    setter(value === '' ? '' : parseInt(value, 10));
    setError('');
  };

  const handleGenerateQuestions = async () => {
    setIsGenerating(true);
    setInterviewQuestions(null);

    const payload = {
      job_description: jobDescription,
      n_questions: openEndedQuestions,
      k_questions: multipleChoiceQuestions,
      l_questions: psychologicalQuestions,
      cv_questions: cvBasedQuestions,
      job_title: jobTitle,
    };

    try {
      const token = getCookie('token');
      const response = await fetch(API_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'api-key': API_TOKEN,
        },
        body: JSON.stringify(payload),
      });

      const contentType = response.headers.get('content-type');
      if (!contentType || !contentType.includes('application/json')) {
        throw new Error('API did not return JSON');
      }

      const data = await response.json();
      console.log('questions', data)
      const formattedQuestions = formatInterviewQuestions(data.questions);
      setRawInterviewQuestions(data.questions);
      setInterviewQuestions(formattedQuestions);
      setIsGenerating(false);
    } catch (error) {
      console.error('Error generating interview questions:', error);
      setError('Failed to generate interview questions. Please try again.');
      setIsGenerating(false);
    }
  };

  const formatInterviewQuestions = (questions) => {
    let parsedQuestions;

    if (typeof questions === 'string') {
      parsedQuestions = JSON.parse(questions.replace(/```json\n|\n```/g, ''));
    } else {
      parsedQuestions = questions;
    }

    const openEnded = parsedQuestions.filter((q) => q.options.length === 0);
    const multipleChoice = parsedQuestions.filter((q) => q.options.length > 0);

    return {
      openEnded: openEnded.map(({ pk, qnum, question, category, skill, options }) => ({
        pk,
        qnum,
        question,
        category,
        skill,
        options
      })),
      multipleChoice: multipleChoice.map(({ pk, qnum, question, category, skill, options }) => ({
        pk,
        qnum,
        question,
        category,
        skill,
        options
      }))
    };
  };


  const isFormValid = () => {
    return (
      openEndedQuestions > 0 &&
      multipleChoiceQuestions > 0 &&
      psychologicalQuestions > 0 &&
      jobTitle &&
      jobDescription
    );
  };

  return (
    <form className="vacancy-form">
      <div className="form-header">
        <h3>Interview Questions</h3>
        <p>Indicate the number and type of questions you would like to ask during the interview.</p>
      </div>

      <div className="form-list">
        <TextField
          label="Open-Ended"
          type="number"
          value={openEndedQuestions}
          onChange={handleQuestionChange(setOpenEndedQuestions)}
          fullWidth
          disabled={isGenerating}
          required
          helperText="Questions based on the job description."
        />
        <TextField
          label="Multiple-Choice"
          type="number"
          value={multipleChoiceQuestions}
          onChange={handleQuestionChange(setMultipleChoiceQuestions)}
          fullWidth
          disabled={isGenerating}
          required
          helperText="Multiple choice questions based on the job description."
        />
        <TextField
          label="Logical"
          type="number"
          value={psychologicalQuestions}
          onChange={handleQuestionChange(setPsychologicalQuestions)}
          fullWidth
          disabled={isGenerating}
          required
          helperText="Logical questions, to test fit for the role and company culture."
        />
        <TextField
          label="CV-Based"
          type="number"
          value={cvBasedQuestions}
          onChange={handleQuestionChange(setCvBasedQuestions)}
          fullWidth
          disabled={isGenerating}
          helperText="Number of questions directly regarding the candidates CV."
        />
      </div>

      <div className="btn-container-genai">
        <button
          className="aigen-btn"
          onClick={handleGenerateQuestions}
          disabled={!isFormValid() || isGenerating}
        >
          <GenerateIcon /> {interviewQuestions?.openEnded?.length ? 'Regenerate Questions' : 'Generate Questions'}
        </button>
      </div>

      {interviewQuestions?.openEnded?.length > 0 && (
        <>
          <div className="form-questions-section">
            <h3>Open-ended questions</h3>
            {interviewQuestions?.openEnded?.map((question, index) => (
              <div key={question.pk} className="form-question-item">
                <GripperIcon className="gripper-icon" /> {/* Gripper Icon */}
                <div className="form-question-content">
                  <p>Question {question.qnum}</p>
                  <p>{question.question}</p>
                </div>
              </div>
            ))}
          </div>

          <div className="form-questions-section">
            <h3>Multiple-choice questions</h3>
            {interviewQuestions?.multipleChoice?.map((question, index) => (
              <div key={question.pk} className="form-question-item">
                <GripperIcon className="gripper-icon" />
                <div className="form-question-content">
                  <p>Question {question.qnum}</p>
                  <p>{question.question}</p>
                  <div>
                    <p>Answers:</p>
                    <ul>
                      {question.options.map((option, i) => (
                        <li key={i}>{option}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}

      <div className="navigation-buttons">
        <button
          className="previous-step-btn"
          onClick={handlePreviousStep}
          disabled={isGenerating}
        >
          Previous step
        </button>
        <button
          className="next-step-btn"
          onClick={handleNextStep}
          disabled={
            isGenerating ||
            !interviewQuestions ||
            !interviewQuestions.openEnded?.length ||
            !interviewQuestions.multipleChoice?.length ||
            !isFormValid()
          }
        >
          Next step
        </button>
      </div>
    </form>
  );
};

export default InterviewQuestionStep;
