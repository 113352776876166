import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Menu, MenuItem } from '@mui/material';

import Vacancies from './components/pickey/vacancies/Vacancies';
import Candidates from './components/pickey/candidates/Candidates';
import Companies from './components/pickey/companies/Companies';

import Profile from './components/kopi/profile/Profile';
import Evaluation from './components/kopi/evaluation/Evaluation';
import PerformanceReview from './components/kopi/performance_review/Performance';

import Login from './components/landing/Login';
import Interview from './components/pickey/interview/Interview';
import ProtectedRoute from './ProtectedRoute';
import './App.css';
import logo from './assets/kodelab-logo.png';
import { ReactComponent as KodelabLogo } from './assets/kodelab-full-logo.svg';
import userIcon from './assets/user-blank.png';
import apiConfig from './config/apiConfig';

const API_GET_AUTH_TOKEN = `${apiConfig.baseURL}${apiConfig.endpoints.getAuthToken}`;
const API_TOKEN = apiConfig.token;

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentApp, setCurrentApp] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const auth = localStorage.getItem('isAuthenticated');
    setIsAuthenticated(auth === 'true');
  }, []);

  const handleLogin = async (username, password) => {
    try {
      const formData = new FormData();
      formData.append('username', username);
      formData.append('password', password);

      const response = await fetch(API_GET_AUTH_TOKEN, {
        method: 'POST',
        headers: {
          'api-key': API_TOKEN
        },
        body: formData
      });

      if (response.ok) {
        const data = await response.json();
        const token = data.access_token;
        document.cookie = `token=${token}; path=/; secure; SameSite=Strict; max-age=3600`;
        setIsAuthenticated(true);
        localStorage.setItem('isAuthenticated', 'true');
        if (currentApp == 'Pickey') {
          navigate('/vacancies');
        } else {
          navigate('/profile')
        }
        return true;
      } else if (response.status === 401 || response.status === 401) {
        console.error('Unauthorized: Invalid username or password');
      }
      return false;
    } catch (error) {
      console.error('Login failed', error);
      return false;
    }
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('isAuthenticated');
    navigate('/');
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogoClick = () => {
    setCurrentApp(currentApp === 'Pickey' ? 'Kopi' : 'Pickey');
  };

  const isLoginPage = location.pathname === '/';
  const isInterviewPage = location.pathname.startsWith('/interview');

  return (
    <div className="app">
      {!isLoginPage && (
        <nav className="navbar">
          <div className="navbar-left">
            <KodelabLogo
              className="logo"
            />
          </div>
          {['Pickey', 'Kopi'].includes(currentApp) && (
            <>
              <div className="navbar-center">
                {currentApp === 'Pickey' ? (
                  <>
                    <NavLink to="/companies" className="nav-link">Companies</NavLink>
                    <NavLink to="/vacancies" className="nav-link">Vacancies</NavLink>
                    <NavLink to="/candidates" className="nav-link">Candidates</NavLink>
                  </>
                ) : (
                  <>
                    <NavLink to="/profile" className="nav-link">Profile</NavLink>
                    <NavLink to="/evaluation" className="nav-link">Evaluation</NavLink>
                    <NavLink to="/performance" className="nav-link">Performance Review</NavLink>
                  </>
                )}
              </div>
              <div className="navbar-right">
                <img
                  src={userIcon}
                  alt="User Profile"
                  className="profile-icon"
                  onClick={handleMenuOpen}
                />
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={handleMenuClose}>Settings</MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleLogout();
                      handleMenuClose();
                    }}
                    style={{ color: 'red' }}
                  >
                    Logout
                  </MenuItem>
                </Menu>
              </div>
            </>
          )}
        </nav>

      )}
      <div className="content">
        <TransitionGroup>
          <CSSTransition
            key={location.key}
            timeout={300}
            classNames="fade"
          >
            <Routes location={location}>
              <Route path="/" element={<Login handleLogin={handleLogin} handleLogoClick={handleLogoClick} currentApp={currentApp} />} />
              <Route path="/vacancies" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Vacancies /></ProtectedRoute>} />
              <Route path="/candidates" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Candidates /></ProtectedRoute>} />
              <Route path="/companies" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Companies /></ProtectedRoute>} />
              <Route path="/profile" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Profile /></ProtectedRoute>} />
              <Route path="/evaluation" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Evaluation /></ProtectedRoute>} />
              <Route path="/performance" element={<ProtectedRoute isAuthenticated={isAuthenticated}><PerformanceReview /></ProtectedRoute>} />
              <Route path="/interview" element={<Interview />} />
            </Routes>
          </CSSTransition>
        </TransitionGroup>
      </div>
    </div>
  );
}

export default App;
