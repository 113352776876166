import React, { useRef, useEffect } from 'react';
import Message from './Message';
import './Interview.css';

const MessageList = ({ messages, acceptTerms, showButtons, showReadyButton, handleReadyClick, startOver }) => {
  const chatMessagesRef = useRef(null);

  useEffect(() => {
    chatMessagesRef.current.scrollTo({
      top: chatMessagesRef.current.scrollHeight,
      behavior: 'smooth'
    });
  }, [messages]);
  

  // Function to split messages at '\n\n' and create new message objects
  const splitMessages = (messages) => {
    const newMessages = [];
    messages.forEach((message) => {
      const splitText = message.text.split('\n\n');
      splitText.reverse(); 
      splitText.forEach((text, index) => {
        newMessages.push({
          sender: message.sender,
          text: text.trim(),
          isCv: message.isCv,
          withButtons: message.withButtons && index === 0, // Show buttons only on the first part
          showReadyButton: message.showReadyButton && index === 0, // Show ready button only on the first part
          showStartOverButton: message.showStartOverButton && index === 0, // Show start over button only on the first part
          isSplit: splitText.length > 1 && index > 0 // Identify split messages
        });
      });
    });
    return newMessages;
  };

  const processedMessages = splitMessages(messages);

  return (
    <div className="chat-messages" ref={chatMessagesRef}>
      {processedMessages.map((message, index) => (
        <div
          key={index}
          className={`message ${message.sender === 'user' ? 'user-message' : 'agent-message'} ${message.isCv ? 'cv-message' : ''} ${message.isSplit ? 'split-message' : ''}`}
        >
          <Message
            sender={message.sender === 'user' ? 'user-message' : 'agent-message'}
            text={message.text}
            isCv={message.isCv}
          />
          {message.withButtons && showButtons && (
            <div className="button-group">
              <button className="decline messageboxbtn" onClick={() => acceptTerms('I decline')}>I decline</button>
              <button className="accept messageboxbtn" onClick={() => acceptTerms('Yes, I have read and agree to the Terms of Service and Privacy policy.')}>
                I accept
              </button>
            </div>
          )}
          {message.showStartOverButton && (
            <div className="button-group">
              <button onClick={startOver}>Start Over</button>
            </div>
          )}
          {message.showReadyButton && showReadyButton && (
            <div className="button-group">
              <button onClick={handleReadyClick}>I'm ready!</button>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default MessageList;
