// CustomSnackbar.js
import React from 'react';
import Snackbar from '@mui/material/Snackbar';

function CustomSnackbar(props) {
  const {
    open,
    onClose,
    autoHideDuration = 3000,
    message,
    backgroundColor = '#BEF5BD',
    textColor = 'black',
    icon,
    iconAlt = 'Icon',
    iconStyle = { marginRight: '8px', height: '22px', width: '22px' },
    messageStyle = {},
    contentStyle = {},
    ...snackbarProps
  } = props;

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      {...snackbarProps}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          backgroundColor: backgroundColor,
          color: textColor,
          padding: '16px',
          borderRadius: '8px',
          textAlign: 'center',
          ...contentStyle,
        }}
      >
        {icon && (
          <img
            src={icon}
            alt={iconAlt}
            style={iconStyle}
          />
        )}
        <span style={messageStyle}>{message}</span>
      </div>
    </Snackbar>
  );
}

export default CustomSnackbar;
