import { CircularProgress } from '@mui/material';
const EmailVerificationModal = ({ candidateEmail, handleSendEmail, loadingEmail, handleStartOver }) => (
  <div className="modal">
    <div className="modal-content">
      <h2>OTP Verification</h2>
      <p>
        {candidateEmail 
          ? `We're going to send a verification email to the one we found in the CV: ${candidateEmail}`
          : 'Email not available. Please upload a CV with a valid email.'}
      </p>
      
      {candidateEmail && (
        <p className="modal-subtitle">Not right? Hit start again and upload a CV where the email is correct.</p>
      )}

      <div className="modal-otp-buttons">
        {candidateEmail ? (
          <>
            <button className="modal-otp-button-empty start-over-button decline" onClick={handleStartOver}>Start Over</button>
            <button className="modal-otp-button-normal" onClick={handleSendEmail} disabled={loadingEmail}>
              {loadingEmail ? <CircularProgress size={14} /> : 'Send OTP'}
            </button>
          </>
        ) : (
          <button className="start-over-button decline" onClick={handleStartOver}>Start Over</button>
        )}
      </div>

    </div>
  </div>
);

export default EmailVerificationModal;
