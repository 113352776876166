import React, { useState, useEffect } from 'react';
import './Companies.css';
import CompanyList from './CompanyList';
import CompanyDetails from './CompanyDetails';
import EditCompanyForm from './EditCompanyForm';
import NewCompanyForm from './NewCompanyForm';
import apiConfig from '../../../config/apiConfig';
import { getCookie } from '../utility/cookieHelpers';
import CustomSnackbar from '../utility/CustomSnackBar';

const API_GET_COMPANIES_ENDPOINT = `${apiConfig.baseURL}${apiConfig.endpoints.getCompanies}`;
const API_CREATE_COMPANY_ENDPOINT = `${apiConfig.baseURL}${apiConfig.endpoints.createCompany}`;
const API_GET_PRODUCT_ENDPOINT = `${apiConfig.baseURL}${apiConfig.endpoints.getProduct}`;
const API_GET_SERVICE_ENDPOINT = `${apiConfig.baseURL}${apiConfig.endpoints.getService}`;

const API_TOKEN = apiConfig.token;

function Companies() {
  const [companies, setCompanies] = useState([]);
  const [isLoadingCompanies, setIsLoadingCompanies] = useState(true);
  const [fetchError, setFetchError] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [products, setProducts] = useState([]);
  const [services, setServices] = useState([]);

  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);

  useEffect(() => {
    fetchCompanies();
  }, []);

  useEffect(() => {
    if (selectedCompany) {
      fetchProducts();
      fetchServices();
    }
  }, [selectedCompany]);

  const fetchProducts = async () => {
    const token = getCookie('token');
    try {
      const productPromises = selectedCompany.products.map(pk =>
        fetch(API_GET_PRODUCT_ENDPOINT, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'api-key': API_TOKEN,
          },
           body: JSON.stringify({ company_pk: selectedCompany.pk, product_pk: pk }),
          // body: JSON.stringify({ product: pk }),
        }).then(res => res.json())
      );
      const productData = await Promise.all(productPromises);
      console.log(productData);
      setProducts(productData);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const fetchServices = async () => {
    const token = getCookie('token');
    try {
      const servicePromises = selectedCompany.services.map(pk =>
        fetch(API_GET_SERVICE_ENDPOINT, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'api-key': API_TOKEN,
          },
            body: JSON.stringify({ company_pk: selectedCompany.pk, service_pk: pk }),
          // body: JSON.stringify({ service: pk }),
        }).then(res => res.json())
      );
      const serviceData = await Promise.all(servicePromises);
      setServices(serviceData);
    } catch (error) {
      console.error('Error fetching services:', error);
    }
  };

  const fetchCompanies = async (selectedCompanyPk = null) => {
    const token = getCookie('token');
    try {
      const response = await fetch(API_GET_COMPANIES_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'api-key': API_TOKEN,
        },
        body: JSON.stringify({ company: 'bananas', pk: '*' }),
      });

      const data = await response.json();
      console.log(data);
      setCompanies(data.companies);

      // Set selected company based on pk or default to the first one
      const companyToSelect = data.companies.find(
        (c) => c.company.pk === selectedCompanyPk
      );
      setSelectedCompany(companyToSelect?.company || data.companies[0]?.company || null);
      setIsLoadingCompanies(false);
    } catch (error) {
      console.warn(error);
      setFetchError('Failed to fetch companies. Please try again.');
      setIsLoadingCompanies(false);
    }
  };

  const handleEditCompany = (company) => {
    if (!company) {
      setIsEditing(false);
      return;
    }
    setSelectedCompany(company);
    setIsEditing(true);
    setIsCreating(false);
  };

  const handleSaveChanges = async (updatedCompany) => {
    try {
      fetchCompanies(updatedCompany.pk);
      setIsEditing(false);
      setIsCreating(false)
    } catch (error) {
      alert('Failed to update company. Please try again.');
    }
  };

  const handleCreateNewCompany = () => {
    setIsCreating(true);
    setIsEditing(false);
    setSelectedCompany(null); // Set selected company to null
  };

  const handleCreateCompany = async (newCompany) => {
    try {
      const token = getCookie('token');
      const response = await fetch(API_CREATE_COMPANY_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'api-key': API_TOKEN,
        },
        body: JSON.stringify(newCompany),
      });
      const data = await response.json();
      
      if (!response.ok) {
        throw new Error('Failed to create company');
      }
      fetchCompanies(data.company.pk);
      setIsCreating(false);
      setSuccessSnackbarOpen(true);
    } catch (error) {
      alert('Failed to create company. Please try again.');
    }
  };

  return (
    <div className="companies-page">
      <CustomSnackbar
        open={successSnackbarOpen} 
        onClose={() => setSuccessSnackbarOpen(false)}
        message="Company created successfully!"
        backgroundColor="#BEF5BD"
        textColor="#155724"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3000}
      />
      <CompanyList
        companies={companies}
        isLoadingCompanies={isLoadingCompanies}
        fetchError={fetchError}
        selectedCompany={selectedCompany}
        onCompanyClick={(company) => {
          setSelectedCompany(company);
          setIsCreating(false);
          setIsEditing(false);
        }}
        onEditCompany={handleEditCompany}
        onCreateNewCompany={handleCreateNewCompany}
      />
      <main className="companies-main">
        {isCreating ? (
          <NewCompanyForm onCreateCompany={handleCreateCompany} />
        ) : isEditing ? (
          <EditCompanyForm
            company={selectedCompany}
            onEditCompany={handleEditCompany}
            onSaveChanges={handleSaveChanges}
            products={products}
            services={services}
          />
        ) : selectedCompany ? (
          <CompanyDetails
            company={selectedCompany}
            onAddCompany={handleCreateNewCompany}
            onEditCompany={handleEditCompany}
            products={products}
            services={services}
          />
        ) : null}
      </main>
    </div>
  );
}

export default Companies;
