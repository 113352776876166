import React from 'react';
import './ReviewStep.css';
import TextField from '@mui/material/TextField';
import { ReactComponent as GripperIcon } from '../../../../assets/BsGripVertical.svg';

const ReviewStep = ({
  jobTitle, setJobTitle,
  salaryRange, setSalaryRange,
  jobDescription,
  interviewQuestions,
  handleEditStep,
  handleNextStep,
  handlePreviousStep
}) => {
  return (
    <div className="vacancy-form">
      <div className="form-header">
        <h3>Review</h3>
        <p>Please check the information</p>
      </div>

      <div className="form-list">
        <TextField
          label="Job position title"
          value={jobTitle}
          fullWidth
          disabled
        />
      </div>

      <TextField
        label="Job description"
        value={jobDescription}
        multiline
        disabled
        fullWidth
      />

      <div className="form-questions-section">
        <h3>Open-ended questions</h3>
        {interviewQuestions?.openEnded?.map((question, index) => (
          <div key={question.pk} className="form-question-item">
            <GripperIcon className="gripper-icon" />
            <div className="form-question-content">
              <p>Question {question.qnum}</p>
              <p>{question.question}</p>
            </div>
          </div>
        ))}
      </div>

      <div className="form-questions-section">
        <h3>Multiple-choice questions</h3>
        {interviewQuestions?.multipleChoice?.map((question, index) => (
          <div key={question.pk} className="form-question-item">
            <GripperIcon className="gripper-icon" />
            <div className="form-question-content">
              <p>Question {question.qnum}</p>
              <p>{question.question}</p>
              <div>
                <p>Answers:</p>
                <ul>
                  {question.options?.map((option, i) => (
                    <li key={i}>{option}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>




      <div className="navigation-buttons">
        <button className="previous-step-btn" onClick={handlePreviousStep}>
          Previous step
        </button>
        <button className="next-step-btn" onClick={handleNextStep}>
          Save Vacancy
        </button>
      </div>
    </div>
  );
};

export default ReviewStep;
