import React, { useRef, useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton'; // Import MUI IconButton component
import { CircularProgress } from '@mui/material'; // Import CircularProgress for loading spinner
import { ReactComponent as UploadIcon } from '../../../assets/PaperclipIcon.svg'; // Upload icon
import { ReactComponent as FileSelectedIcon } from '../../../assets/CheckIcon.svg'; // New icon for when a file is selected

import './Modals.css';

const useCheckFileSelected = (cvFile, setIsFileSelected) => {
  useEffect(() => {
    if (!cvFile) {
      setIsFileSelected(false);
    }
  }, [cvFile, setIsFileSelected]);
};

const NameMismatchModal = ({
  newName,
  setNewName,
  retrySaveCandidate,
  loading,
  handleFileUpload,
  showUploadMessage,
  cvFile,
}) => {
  const fileInputRef = useRef(null);
  const [isFileSelected, setIsFileSelected] = useState(false); // State to track file selection

  useCheckFileSelected(cvFile, setIsFileSelected);

  const handleFileChange = (e) => {
    handleFileUpload(e);
    setIsFileSelected(e.target.files.length > 0); // Update state based on file selection
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Name Mismatch</h2>
        <p className="modal-description">
          {showUploadMessage
            ? "That still doesn't seem right, try uploading a new CV where the name matches the one you've entered here."
            : "The name you entered does not match the name from your CV. Please re-enter your name:"}
        </p>
        <div className="modal-otp-container">
          <input
            type="text"
            placeholder="Enter your name"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            disabled={loading}
            className="modal-otp-input"
          />

          {showUploadMessage && (
            <>
              <label htmlFor="cv-upload" className="custom-file-upload namemismatch">
                <IconButton component="span" disabled={loading}>
                  {isFileSelected ? <FileSelectedIcon /> : <UploadIcon />}
                </IconButton>
              </label>
              <input
                id="cv-upload"
                type="file"
                accept="application/pdf"
                onChange={handleFileChange}
                className="hidden-input"
                disabled={loading}
                ref={fileInputRef}
              />
            </>
          )}

          <button
            onClick={retrySaveCandidate}
            disabled={loading}
            className="modal-otp-button"
          >
            {loading ? <CircularProgress size={14} /> : 'Submit'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default NameMismatchModal;
