import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import spinnerGif from '../../../assets/kodelab_spinner.gif';

import './Interview.css';
import MessageList from './MessageList';
import MessageBox from './MessageBox';
import EmailVerificationModal from './EmailVerificationModal';
import OtpVerificationModal from './OtpVerificationModal';
import ErrorModal from './ErrorModal';
import NameMismatchModal from './NameMismatchModal';
import apiConfig from '../../../config/apiConfig';

const SAVE_CANDIDATE_ENDPOINT = `${apiConfig.baseURL}${apiConfig.endpoints.saveCandidate}`;
const VACANY_DETAILS_ENDPOINT = `${apiConfig.baseURL}${apiConfig.endpoints.getVacancyDetails}`;
const VALIDATE_EMAIL_ENDPOINT = `${apiConfig.baseURL}${apiConfig.endpoints.validateEmail}`;
const GENERATE_OTP_ENDPOINT = `${apiConfig.baseURL}${apiConfig.endpoints.generateOtp}`;
const VERIFY_OTP_ENDPOINT = `${apiConfig.baseURL}${apiConfig.endpoints.verifyOtp}`;
const START_INTERVIEW_ENDPOINT = `${apiConfig.baseURL}${apiConfig.endpoints.startInterview}`;
const API_TOKEN = apiConfig.token;

const Interview = () => {
  const location = useLocation();
  const [interviewId, setInterviewId] = useState(null);
  const [jobTitle, setJobTitle] = useState('Not Found');
  const [messages, setMessages] = useState([]);
  const [userName, setUserName] = useState('');
  const [candidateEmail, setCandidateEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [cvFile, setCvFile] = useState(null);
  const [newMessage, setNewMessage] = useState('');
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [inputDisabled, setInputDisabled] = useState(true);
  const [showButtons, setShowButtons] = useState(true);
  const [showReadyButton, setShowReadyButton] = useState(true);
  const [isInitialStep, setIsInitialStep] = useState(true);
  const [isInterviewDone, setIsInterviewDone] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [loadingOtp, setLoadingOtp] = useState(false);
  const [emailNotAllowed, setEmailNotAllowed] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showCompletionModal, setShowCompletionModal] = useState(false);
  const [userSessionId, setUserSessionId] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const [listingClosed, setListingClosed] = useState(false);
  const [showNameMismatchModal, setShowNameMismatchModal] = useState(false);
  const [newName, setNewName] = useState('');
  const [showUploadMessage, setShowUploadMessage] = useState(false);
  const [progressBarValue, setProgressBarValue] = useState(0);

  const onFileChange = (e) => {
    handleFileUpload(e);
  };

  const saveToLocalStorage = (key, value) => {
    if (interviewId) {
      localStorage.setItem(`interview_${interviewId}_${key}`, JSON.stringify(value));
    }
  };

  const loadFromLocalStorage = (key, defaultValue) => {
    if (interviewId) {
      const savedValue = localStorage.getItem(`interview_${interviewId}_${key}`);
      return savedValue ? JSON.parse(savedValue) : defaultValue;
    }
    return defaultValue;
  };

  useEffect(() => {
    if (!interviewId) return; //We must have an interviewID to load data!

    try {
      const showEmailModal = loadFromLocalStorage('show_email_modal', false);
      const showOtpModal = loadFromLocalStorage('show_otp_modal', false);
      const emailVerified = loadFromLocalStorage('email_verified', false);
      const savedMessages = loadFromLocalStorage('chat_history', []);
      const isReady = loadFromLocalStorage('ready', false);
      const termsAccepted = loadFromLocalStorage('terms_accepted', false);
      const sessionId = loadFromLocalStorage('user_session_id', null);
      const uploadMessageState = loadFromLocalStorage('show_upload_message', false);
      const nameMismatchModalState = loadFromLocalStorage('show_name_mismatch_modal', false);
      const showButtons = loadFromLocalStorage('show_buttons', true);

      console.log('Messages on Load:', savedMessages);
      console.log('Ready State on Load:', isReady);
      console.log('Terms Accepted on Load:', termsAccepted);
      console.log('User Session ID on Load:', sessionId);

      // Set the loaded values to state
      setShowEmailModal(showEmailModal);
      setShowOtpModal(showOtpModal);
      setEmailVerified(emailVerified);

      if (savedMessages.length > 0) {
        setMessages(savedMessages);

        if (!isReady) {
          setIsInitialStep(true);
          setInputDisabled(true);
          if (termsAccepted) {
            setInputDisabled(false);
            setShowReadyButton(true);
          }
        } else {
          setIsInitialStep(false);
          setInputDisabled(!isReady || !termsAccepted);
          setShowReadyButton(!isReady);
        }

      } else {
        setInputDisabled(true);
      }

      setTermsAccepted(termsAccepted);
      setUserSessionId(sessionId);
      setShowUploadMessage(uploadMessageState);
      setShowNameMismatchModal(nameMismatchModalState);
      setShowButtons(showButtons);

      console.log(inputDisabled);
    } catch (error) {
      console.error("Error loading data from local storage:", error);
    }
  }, [interviewId]); // Run when interviewId changes


  // Save states when they change
  useEffect(() => {
    saveToLocalStorage('show_email_modal', showEmailModal);
  }, [showEmailModal, interviewId]);

  useEffect(() => {
    saveToLocalStorage('show_otp_modal', showOtpModal);
  }, [showOtpModal, interviewId]);

  useEffect(() => {
    saveToLocalStorage('email_verified', emailVerified);
  }, [emailVerified, interviewId]);

  useEffect(() => {
    saveToLocalStorage('show_upload_message', showUploadMessage);
  }, [showUploadMessage, interviewId]);

  useEffect(() => {
    saveToLocalStorage('show_name_mismatch_modal', showNameMismatchModal);
  }, [showNameMismatchModal, interviewId]);

  useEffect(() => {
    saveToLocalStorage('candidate_email', candidateEmail);
  }, [candidateEmail, interviewId]);

  useEffect(() => {
    if (messages.length > 0) {
      saveToLocalStorage('chat_history', messages);
    }
  }, [messages]);

  // Extract interview ID from URL query parameters
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    setInterviewId(id);
  }, [location.search]);

  // Fetch interview details when interviewId is set
  useEffect(() => {
    if (interviewId) {
      fetchInterviewDetails(interviewId);
      const interviewDone = loadFromLocalStorage('done', false);
      if (interviewDone) {
        setShowCompletionModal(true);
        setMessages([]);
      }
    }
  }, [interviewId]);

  useEffect(() => {
    if (messages.length === 0 && !showCompletionModal) {
      setMessages([
        {
          text: `Hey, I'm Pickey! Excited to have you here for the interview. Before we start, could you please read and accept our [Terms of Service](https://kodelab.io/legal/termsConditions), [Privacy Policy](https://kodelab.io/legal/privacyPolicy) and [Cookie Policy](https://kodelab.io/legal/cookiePolicy).\n<span style="font-size: 14px; color: grey;">By clicking "I accept" you confirm that you have read, understood, and agreed to our Terms of Service, Privacy Policy, and Cookie Policy.</span>`,
          sender: 'agent',
          withButtons: true
        },
        ...messages // Prepend new messages
      ]);
    }
  }, [emailVerified]);

  const disableCopyPaste = (e) => {
    e.preventDefault();
    return false;
  };

  useEffect(() => {
    document.addEventListener('copy', disableCopyPaste);
    document.addEventListener('cut', disableCopyPaste);
    document.addEventListener('paste', disableCopyPaste);

    return () => {
      document.removeEventListener('copy', disableCopyPaste);
      document.removeEventListener('cut', disableCopyPaste);
      document.removeEventListener('paste', disableCopyPaste);
    };
  }, []);

  //END OF EFFECTS
  /** ||||||||||||||||||||||||| */
  //START OF INTERVIEW LOGIC

  //Get the title and whether its closed
  const fetchInterviewDetails = async (id) => {
    try {
      const response = await fetch(VACANY_DETAILS_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'api-key': API_TOKEN
        },
        body: JSON.stringify({ vacancy: id })
      });

      if (response.ok) {
        const data = await response.json();
        if (data.vacancy) {
          const vacancy = data.vacancy;
          setJobTitle(vacancy.job_title);
          if (!vacancy.active) {
            setListingClosed(true);
          }
        } else {
          setListingClosed(true);
        }
      } else {
        throw new Error('Network response was not ok');
      }
    } catch (error) {
      setListingClosed(true);
    }
  };

  //Called when user submits name and CV
  const onBoardUser = async () => {
    const response = await saveCandidate(userName, cvFile);

    if (response) {
      if (response.status === 409) {
        setShowNameMismatchModal(true);
        saveToLocalStorage('show_name_mismatch_modal', true);
        return;
      }

      if (!response.ok) {
        alert('Failed to save candidate. Please try again.');
        return;
      }

      const data = await response.json();
      handleSuccessfulSave(data);
    }
  };

  //START: Three functions handle the logic for saving & validating candidate object
  const saveCandidate = async (name, file) => {
    setIsSending(true);

    const formData = new FormData();
    formData.append('name', name);
    formData.append('resume', file);
    formData.append('vacancy', interviewId);

    try {
      const response = await fetch(SAVE_CANDIDATE_ENDPOINT, {
        method: 'POST',
        headers: {
          'api-key': API_TOKEN,
        },
        body: formData,
      });

      return response;
    } catch (error) {
      console.error('Error saving candidate:', error);
      alert('Failed to save candidate. Please try again.');
    } finally {
      setIsSending(false);
    }
  };

  const retrySaveCandidate = async () => {
    if (newName.trim() === '' || isSending) return; // Prevent multiple sends

    try {
      const response = await saveCandidate(newName, cvFile);

      if (response) {
        if (response.status === 409 || response.status === 422) {
          setShowUploadMessage(true);
          saveToLocalStorage('show_upload_message', true);
          setNewName('');
          setCvFile(null);
          return;
        }

        if (!response.ok) {
          alert('Failed to save candidate. Please try again.');
          setCvFile(null); // Clear the file
          return;
        }

        const data = await response.json();
        handleSuccessfulSave(data);
        setShowNameMismatchModal(false); // Hide the modal
        saveToLocalStorage('show_name_mismatch_modal', false);
        saveToLocalStorage('show_upload_message', false);
      }
    } catch (error) {
      console.error('Error saving candidate:', error);
      alert('Failed to save candidate. Please try again.');
      setCvFile(null); // Clear the file
    }
  };

  const handleSuccessfulSave = (data) => {
    console.log('Candidate saved successfully:', data);

    if (data.candidate_pk) {
      localStorage.setItem('candidate_pk', data.candidate_pk);
      setMessages([
        { text: `Name: ${data.name}`, sender: 'user' },
        { text: 'CV: FileName.pdf', sender: 'user', isCv: true },
        ...messages
      ]);
      setUserName('');
      setCvFile(null);
      setInputDisabled(true);
      setMessages(prevMessages => [
        { text: 'Thanks! Let me know when you\'re ready to start, and we\'ll begin the interview.', sender: 'agent', showReadyButton: true },
        ...prevMessages
      ]);
      setIsInitialStep(false);

      // Start the OTP process
      setCandidateEmail(data.email);
      setShowEmailModal(true);
    } else {
      alert('Failed to get candidate_pk');
    }
  };
  //END: Three functions handle the logic for saving & validating candidate object

  //Handle user message submit
  const sendMessage = async () => {
    if (newMessage.trim() === '') return;

    const candidatePk = localStorage.getItem('candidate_pk');
    if (!candidatePk) {
      alert('Candidate information is missing. Please start over.');
      return;
    }

    setMessages(prevMessages => [
      { text: newMessage, sender: 'user' },
      ...prevMessages
    ]);

    setNewMessage('');
    setInputDisabled(true);

    const payload = { vacancy_id: interviewId, candidate_pk: candidatePk, message: newMessage, user_session_id: userSessionId };

    try {
      const response = await fetch(START_INTERVIEW_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'api-key': API_TOKEN
        },
        body: JSON.stringify(payload)
      });
      if (response.ok) {

        const data = await response.json();
        let agentMessage = data.result;
        console.log('Agent response:', data);

        setProgressBarValue(data.progress);

        if (agentMessage.includes('DONE.')) endInterview(agentMessage);

        setMessages(prevMessages => [
          { text: agentMessage, sender: 'agent' },
          ...prevMessages
        ]);

      } else {
        throw new Error('Response not OK');
      }
    } catch (error) {
      setMessages(prevMessages => [
        { text: 'Failed to send the message. Please try again later.', sender: 'agent' },
        ...prevMessages
      ]);
    } finally {
      //Only re-enable inputs if the interview did not end.
      if (!isInterviewDone) {
        setInputDisabled(false);
      }
    }
  };

  //Ends the interview.
  const endInterview = (agentMessage) => {
    agentMessage = agentMessage.replace('DONE.', '');
    agentMessage += 'It was great chatting with you today! Thank you for sharing your experience and interest in our company. We’ll review everything and be in touch soon. Take care!';
    setIsInterviewDone(true);
    saveToLocalStorage('done', true);
    localStorage.removeItem('otpVerified');
    localStorage.removeItem(`interview_${interviewId}_chat_history`);
    localStorage.removeItem(`interview_${interviewId}_ready`);
    localStorage.removeItem(`interview_${interviewId}_terms_accepted`);
  };

  //Handle the users choice to accept or delice T&Cs
  const acceptTerms = (text) => {
    setMessages(prevMessages => [
      { text, sender: 'user' },
      ...prevMessages
    ]);
    setShowButtons(false);

    const accepted = (text !== 'I decline');
    setTermsAccepted(accepted);

    saveToLocalStorage('terms_accepted', accepted);
    saveToLocalStorage('show_buttons', false);
    if (text === 'I decline') {
      //Set the cannot continue message and offer the start over button
      setMessages(prevMessages => [
        { text: 'We respect your decision. Unfortunately, we cannot proceed with the interview without your acceptance of the Terms of Service, Privacy Policy, and Cookie Policy. If you would like to start over, please click the Start Over button. If not, we wish you the best of luck with your job search journey. Thank you for your understanding.', sender: 'agent', showStartOverButton: true },
        ...prevMessages
      ]);
    } else {
      setMessages(prevMessages => [
        { text: 'Thank you! Please provide your full name and attach your CV.', sender: 'agent' },
        ...prevMessages
      ]);
    }
    //If terms are accepted: Input is enabled
    //If terms declined: Input is disabled
    setInputDisabled(!accepted);
  };


  //Handle a state reset to the beginning of the interview
  const startOver = () => {
    setMessages([
      {
        text: `Hey, I'm Pickey! Excited to have you here for the interview. Before we start, could you please read and accept our [Terms of Service](https://kodelab.io/legal/termsConditions), [Privacy Policy](https://kodelab.io/legal/privacyPolicy) and [Cookie Policy](https://kodelab.io/legal/cookiePolicy).\n<span style="font-size: 14px; color: grey;">By clicking "I accept" you confirm that you have read, understood, and agreed to our Terms of Service, Privacy Policy, and Cookie Policy.</span>`,
        sender: 'agent',
        withButtons: true
      }
    ]);
    setShowButtons(true);
    setInputDisabled(true);
    setShowReadyButton(true);
    setIsInitialStep(true);
    setTermsAccepted(false);
    saveToLocalStorage('terms_accepted', false);
    saveToLocalStorage('show_buttons', true);
  };

  const OTPStartOver = () => {
    setMessages([
      {
        text: 'Thank you! Please provide your full name and attach your CV.',
        sender: 'agent'
      },
      {
        text: 'Yes, I have read and agreed to the Terms of Service and Privacy Policy.',
        sender: 'user'
      },
      {
        text: `Hey, I'm Pickey! Excited to have you here for the interview. Before we start, could you please read and accept our [Terms of Service](https://kodelab.io/legal/termsConditions), [Privacy Policy](https://kodelab.io/legal/privacyPolicy) and [Cookie Policy](https://kodelab.io/legal/cookiePolicy).\n<span style="font-size: 14px; color: grey;">By clicking "I accept" you confirm that you have read, understood, and agreed to our Terms of Service, Privacy Policy, and Cookie Policy.</span>`,
        sender: 'agent',
        withButtons: false
      }
    ]);
    setCvFile(null);
    setShowEmailModal(false);
    setInputDisabled(false);
    setIsInitialStep(true);
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCvFile(file);
    }
  };

  const handleReadyClick = async () => {
    const candidatePk = localStorage.getItem('candidate_pk');
    if (!candidatePk) {
      alert('Candidate information is missing. Please start over.');
      return;
    }

    setMessages(prevMessages => [
      { text: "I'm ready!", sender: 'user' },
      ...prevMessages
    ]);
    setShowReadyButton(false);
    saveToLocalStorage('ready', true);

    const payload = { vacancy_id: interviewId, candidate_pk: candidatePk };

    try {
      const response = await fetch(START_INTERVIEW_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'api-key': API_TOKEN
        },
        body: JSON.stringify(payload)
      });

      if (response.ok) {
        const data = await response.json();
        setMessages(prevMessages => [
          { text: data.result, sender: 'agent' },
          ...prevMessages
        ]);
        setUserSessionId(data.user_session_id); // Save user session ID
        saveToLocalStorage('user_session_id', data.user_session_id); // Save to local storage
        setInputDisabled(false);
      } else {
        throw new Error('Network response was not ok');
      }
    } catch (error) {
      setMessages(prevMessages => [
        { text: 'Failed to start the interview. Please try again later.', sender: 'agent' },
        ...prevMessages
      ]);
    }
  };

  const handleSendEmail = async () => {
    setLoadingEmail(true);
    try {
      const payload = { email: candidateEmail, vacancy: interviewId }; // Use candidateEmail
      const validateResponse = await fetch(VALIDATE_EMAIL_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'api-key': API_TOKEN
        },
        body: JSON.stringify(payload)
      });

      if (validateResponse.status == 409) {
        setEmailNotAllowed(true);
        setShowEmailModal(false);
        setLoadingEmail(false);
        return;
      }

      const response = await fetch(GENERATE_OTP_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'api-key': API_TOKEN
        },
        body: JSON.stringify({ email: candidateEmail })
      });

      if (response.ok) {
        setShowEmailModal(false);
        setShowOtpModal(true);
        saveToLocalStorage('show_email_modal', false); // Save state
        saveToLocalStorage('show_otp_modal', true); // Save state
      } else {
        alert('Failed to send OTP. Please try again.');
      }

      // Assume OTP sent successfully
      setShowOtpModal(true);
      setShowEmailModal(false);
    } catch (error) {
      alert('Failed to send OTP. Please try again.');
    } finally {
      setLoadingEmail(false);
    }
  };

  const handleVerifyOtp = async () => {
    setLoadingOtp(true);
    try {
      const payload = {
        email: candidateEmail,
        otp: otp
      }

      const response = await fetch(VERIFY_OTP_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'api-key': API_TOKEN
        },
        body: JSON.stringify(payload)
      });

      if (response.ok) {
        setShowOtpModal(false);
        setEmailVerified(true);
        saveToLocalStorage('email_verified', true);
        localStorage.setItem('otpVerified', 'true');
        console.log('OTP Verified and Stored in Local Storage');
      } else {
        alert('Invalid OTP. Please try again.');
      }
    } catch (error) {
      alert('Invalid OTP. Please try again.');
    } finally {
      setLoadingOtp(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevents the default action (form submission)
      if (isInitialStep) {
        onBoardUser();
      } else {
        sendMessage();
      }
    }
  };

  const isSendEnabled = userName.trim() !== '' && cvFile !== null;

  return (
    <div className="interview-page">
      {isSending && (
        <div style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 1000 // Ensure it's above other content
        }}>
          <img src={spinnerGif} alt="Loading..." style={{ width: '64px', height: '64px' }} />
        </div>
      )}

      <div className={`chat-container ${showCompletionModal ? 'hidden' : ''}`}>
        <div className="chat-box">
          <h2 className="chat-title">
            {jobTitle !== "Not Found" ? `Interview for ${jobTitle}` : "Interview"}
          </h2>

          <div className="progress-bar-container">
            <div className="progress-title">Progress - {progressBarValue}%</div>
            <div className="progress-bar">
              <div className="progress" style={{ width: `${progressBarValue}%` }}></div>
            </div>
          </div>
          <MessageList
            messages={messages}
            acceptTerms={acceptTerms}
            showButtons={showButtons && !termsAccepted}
            showReadyButton={showReadyButton}
            handleReadyClick={handleReadyClick}
            startOver={startOver}
          />
          <MessageBox
            isInitialStep={isInitialStep}
            userName={userName}
            setUserName={setUserName}
            onBoardUser={onBoardUser}
            isSendEnabled={isSendEnabled}
            inputDisabled={inputDisabled || !termsAccepted}
            newMessage={newMessage}
            setNewMessage={setNewMessage}
            sendMessage={sendMessage}
            handleKeyPress={handleKeyPress}
            isInterviewDone={isInterviewDone}
            isSending={isSending}
            onFileChange={onFileChange}
            cvFile={cvFile}
          />
        </div>
      </div>

      {showTermsModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Terms of Service</h2>
            <p>Content of the Terms of Service...</p>
            <button onClick={() => setShowTermsModal(false)}>Done</button>
          </div>
        </div>
      )}

      {showPrivacyModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Privacy Policy</h2>
            <p>Content of the Privacy Policy...</p>
            <button onClick={() => setShowPrivacyModal(false)}>Done</button>
          </div>
        </div>
      )}

      {emailNotAllowed && (
        <ErrorModal
          title="Email Not Allowed"
          message="This email has already been used to start an interview. You cannot do the interview more than once."
        />
      )}

      {!emailVerified && showEmailModal && (
        <EmailVerificationModal
          candidateEmail={candidateEmail}
          handleSendEmail={handleSendEmail}
          loadingEmail={loadingEmail}
          handleStartOver={OTPStartOver}
        />
      )}

      {!emailVerified && showOtpModal && (
        <OtpVerificationModal
          otp={otp}
          setOtp={setOtp}
          handleVerifyOtp={handleVerifyOtp}
          loadingOtp={loadingOtp}
        />
      )}

      {showCompletionModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Thank you for completing the interview!</h2>
            <p>We’ll be in touch soon. Take care!</p>
          </div>
        </div>
      )}

      {listingClosed && (
        <div className="modal">
          <div className="modal-content">
            <h2>Listing Closed</h2>
            <p>This job listing has been closed. Thank you for your interest.</p>
          </div>
        </div>
      )}

      {showNameMismatchModal && (
        <NameMismatchModal
          newName={newName}
          setNewName={setNewName}
          retrySaveCandidate={retrySaveCandidate}
          loading={isSending}
          handleFileUpload={handleFileUpload}
          showUploadMessage={showUploadMessage}
          cvFile={cvFile} // Pass the cvFile state
        />
      )}
    </div>
  );
};

export default Interview;
