import React, { useState, useRef, useEffect } from 'react';
import IconButton from '@mui/material/IconButton'; // Import MUI IconButton component
import { CircularProgress } from '@mui/material'; // Import CircularProgress for loading spinner
import { ReactComponent as UploadIcon } from '../../../assets/PaperclipIcon.svg'; // Default upload icon
import { ReactComponent as SendIcon } from '../../../assets/SendIcon.svg'; // Send icon
import { ReactComponent as FileSelectedIcon } from '../../../assets/CheckIcon.svg'; // New icon for when a file is selected
import './Interview.css';

const useCheckFileSelected = (cvFile, setIsFileSelected) => {
  useEffect(() => {
    if (!cvFile) {
      setIsFileSelected(false);
    }
  }, [cvFile, setIsFileSelected]);
};

const MessageBox = ({
  isInitialStep,
  userName,
  setUserName,
  onBoardUser,
  isSendEnabled,
  inputDisabled,
  newMessage,
  setNewMessage,
  sendMessage,
  handleKeyPress,
  isInterviewDone,
  isSending,
  onFileChange,
  cvFile
}) => {
  const textareaRef = useRef(null);
  const [isFileSelected, setIsFileSelected] = useState(false); // State to track file selection

  useEffect(() => {
    const sendButton = document.querySelector('.message-box-icons button');
    if (sendButton) {
      if (inputDisabled || isInterviewDone) {
        sendButton.classList.remove('enabled');
      } else {
        sendButton.classList.add('enabled');
      }
    }
  }, [inputDisabled, isInterviewDone]);

  useCheckFileSelected(cvFile, setIsFileSelected);

  const handleFileInputChange = (event) => {
    onFileChange(event); // Existing file change handler
    setIsFileSelected(event.target.files.length > 0); // Update state based on file selection
  };

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      // Adjust height automatically based on scroll height
      const adjustHeight = () => {
        textarea.style.height = 'auto'; // Reset the height
        textarea.style.height = Math.min(textarea.scrollHeight, 200) + 'px'; // Set the new height up to max 200px
      };

      adjustHeight(); // Initial adjustment
      textarea.addEventListener('input', adjustHeight);

      // Cleanup event listener on unmount
      return () => textarea.removeEventListener('input', adjustHeight);
    }
  }, [newMessage, userName, isInitialStep]);

  return (
    <div className="message-box">
      <textarea
        ref={textareaRef}
        placeholder={isInitialStep ? "Type your full name here..." : "Type your message here..."}
        value={isInitialStep ? userName : newMessage}
        onChange={(e) => isInitialStep ? setUserName(e.target.value) : setNewMessage(e.target.value)}
        onKeyPress={handleKeyPress}
        disabled={inputDisabled || isInterviewDone || isSending}
      />
      <div className="message-box-icons">
        <label htmlFor="file-upload" className="custom-file-upload">
          <IconButton 
            component="span" 
            disabled={isSending || inputDisabled}>
              {isSending ? null : isFileSelected ? <FileSelectedIcon /> : <UploadIcon />} 
          </IconButton>
        </label>
        <input
          id="file-upload"
          type="file"
          accept=".pdf, .doc, .docx, .rtf, .txt"
          onChange={handleFileInputChange} 
          className="hidden-input"
          disabled={isSending || inputDisabled}
        />

        <IconButton
          onClick={isInitialStep ? onBoardUser : sendMessage}
          disabled={
            (isInitialStep ? !isSendEnabled : newMessage.trim() === '') || isSending || inputDisabled
          }
        >
          {isSending ? <CircularProgress size={24} /> : <SendIcon className="custom-send-icon" />}
        </IconButton>
      </div>
    </div>
  );
};

export default MessageBox;
