import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw'; // Import rehypeRaw to render raw HTML
import './Interview.css';

const Message = ({ sender, text }) => {

  const processedText = text.replace(/\n/g, '\n\n');

  return (
    <div className={`message ${sender}`}>
      <ReactMarkdown
        components={{
          a: ({ node, ...props }) => (
            <a {...props} target="_blank" rel="noopener noreferrer" />
          ),
        }}
        rehypePlugins={[rehypeRaw]} // Use rehypeRaw to allow raw HTML
      >
        {processedText}
      </ReactMarkdown>
    </div>
  );
};

export default Message;
