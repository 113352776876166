import React from 'react';
import './ProfileDetails.css';

const ProfileDetails = () => {
  return (
    <div className="profile-details">
      <section className="experience-section">
        {/* Experience content goes here */}
      </section>
      <section className="background-section">
        {/* Background content goes here */}
      </section>
      <section className="skills-section">
        {/* Skills content goes here */}
      </section>
    </div>
  );
};

export default ProfileDetails;
