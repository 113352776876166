import React from 'react';
import './Vacancies.css';

const InterviewQuestions = ({ questions }) => {
  return (
    <div className="questions-section">
      <h3>Interview Questions</h3>
      {questions.length > 0 ? (
        questions.map((question, index) => (
          <div key={index} className="question-item">
            <p><strong>Question {question.qnum}</strong></p>
            <p>{question.question}</p>
            {question.options && (
              <ul>
                {question.options.map((option, i) => (
                  <li key={i}>{option}</li>
                ))}
              </ul>
            )}
          </div>
        ))
      ) : (
        <p>No questions available</p>
      )}
    </div>
  );
};

export default InterviewQuestions;
