import React, { useState, useEffect } from 'react';
import './Candidates.css';
import apiConfig from '../../../config/apiConfig';
import CandidateList from './CandidateList';
import CandidateDetails from './CandidateDetails';
import EditCandidate from './EditCandidate';

import { getCookie } from '../utility/cookieHelpers';

const API_GET_CANDIDATES_ENDPOINT = `${apiConfig.baseURL}${apiConfig.endpoints.getCandidates}`;
const API_TOKEN = apiConfig.token;
const COMPANY_NAME = apiConfig.company_name;

function Candidates() {
  const [candidates, setCandidates] = useState([]);
  const [isLoadingCandidates, setIsLoadingCandidates] = useState(true);
  const [fetchError, setFetchError] = useState(null);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    fetchCandidates();
  }, []);

  const fetchCandidates = async () => {
    const token = getCookie('token');
    try {
      const response = await fetch(API_GET_CANDIDATES_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'api-key': API_TOKEN
        },
        body: JSON.stringify({ company: COMPANY_NAME })
      });

      if (!response.ok) {
        throw new Error('Failed to fetch candidates');
      }

      const data = await response.json();
      setCandidates(data.candidates);
      setSelectedCandidate(data.candidates[0]);
      console.log('candidates', data.candidates);
      setIsLoadingCandidates(false);
    } catch (error) {
      setFetchError('Failed to fetch candidates. Please try again.');
      setIsLoadingCandidates(false);
    }
  };

  const handleCandidateClick = (candidate) => {
    setSelectedCandidate(candidate);
    setIsEditing(false);
  };

  const handleEditCandidate = (candidate) => {
    setSelectedCandidate(candidate);
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
  };

  const handleDeleteCandidate = async (id) => {
    try {
      const token = getCookie('token');
      const response = await fetch(`${apiConfig.baseURL}${apiConfig.endpoints.deleteCandidate}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'api-key': API_TOKEN,
        },
        body: JSON.stringify({ candidate_id: id })
      });

      if (!response.ok) {
        throw new Error('Failed to delete candidate');
      }

      alert('Candidate deleted successfully!');
      setCandidates(candidates.filter(candidate => candidate.pk !== id));
    } catch (error) {
      console.error('Error deleting candidate:', error);
      alert('Failed to delete candidate. Please try again.');
    }
  };

  return (
    <div className="candidates-page">
      <CandidateList
        candidates={candidates}
        isLoadingCandidates={isLoadingCandidates}
        fetchError={fetchError}
        selectedCandidate={selectedCandidate}
        onCandidateClick={handleCandidateClick}
        onEditCandidate={handleEditCandidate}
        onDeleteCandidate={handleDeleteCandidate}
      />
      <main className="candidates-main">
        {isEditing ? (
          <EditCandidate
            candidate={selectedCandidate}
            onCancelEdit={handleCancelEdit}
            onFetchCandidates={fetchCandidates}
          />
        ) : (
          <CandidateDetails candidate={selectedCandidate} />
        )}
      </main>
    </div>
  );
}

export default Candidates;
