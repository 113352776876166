import React, { useState } from 'react';
import { Menu, MenuItem, Button } from '@mui/material';
import back_arrow from '../../../assets/BsChevronLeft.png';
import { ReactComponent as SearchIcon } from '../../../assets/SearchIcon.svg';

import './VacancyList.css';

const VacancyList = ({
  vacancies,
  isLoadingVacancies,
  fetchError,
  selectedVacancy,
  handleCreateNew,
  handleVacancyClick,
  handleDeleteVacancy,
  startEditingVacancy,
  copyLinkToClipboard,
  filter,
  searchQuery,
  setFilter,
  setSearchQuery,
  isNewVacancyShown,
  newVacancyStep,
  setNewVacancyStep,
  goBack
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [currentVacancy, setCurrentVacancy] = useState(null);

  const handleMenuOpen = (event, vacancy) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setCurrentVacancy(vacancy);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setCurrentVacancy(null);
  };

  const handleFilterMenuOpen = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterMenuClose = () => {
    setFilterAnchorEl(null);
  };

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    handleFilterMenuClose();
  };

  return (
    <aside className="vacancies-sidebar">
      {isNewVacancyShown ? (
        <div className="vacancy-sidebar-new-header">
          <div className="vacancies-sidebar-title">
            <div className="vacancy-eval-back-btn">
              <img src={back_arrow} className="back-arrow-icon" alt="Back" />
              <p onClick={goBack}>Go back</p>
            </div>
          </div>
          <h2>New vacancy</h2>
          <ul>
            <li
              className={newVacancyStep === 1 ? 'active' : ''}
            >
              Job description
            </li>
            <li
              className={newVacancyStep === 2 ? 'active' : ''}
            >
              Interview questions
            </li>
            <li
              className={newVacancyStep === 3 ? 'active' : ''}
            >
              Review
            </li>
            <li
              className={`vacancy-sidebar-new-header li ${newVacancyStep === 4 ? 'active' : ''} success`}
            >
              Success
            </li>
          </ul>
        </div>
      ) : (
        <div className="vacancies-sidebar-header">
          <div className="vacancies-sidebar-title">
            <h3>Vacancies</h3>
            <button className="create-new-btn" onClick={handleCreateNew}>
              + Create new
            </button>
          </div>
          <div className="search-and-filter">
            <div className="search-bar-container">
              <SearchIcon className="search-icon" />
              <input
                type="text"
                className="search-bar"
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <Button
              className="filter-btn"
              onClick={handleFilterMenuOpen}
              variant="outlined"
            >
              <span>{filter.charAt(0).toUpperCase() + filter.slice(1)}</span>
            </Button>
            <Menu
              anchorEl={filterAnchorEl}
              open={Boolean(filterAnchorEl)}
              onClose={handleFilterMenuClose}
            >
              <MenuItem onClick={() => handleFilterChange('all')}>All</MenuItem>
              <MenuItem onClick={() => handleFilterChange('active')}>Active</MenuItem>
              <MenuItem onClick={() => handleFilterChange('inactive')}>Inactive</MenuItem>
            </Menu>
          </div>
        </div>
      )}
      {/* Render the list of vacancies or messages when not creating a new vacancy */}
      {!isNewVacancyShown && (
        <ul className="vacancy-list">
          {isLoadingVacancies ? (
            <li className="no-vacancies-message">Loading vacancies...</li>
          ) : fetchError ? (
            <li className="no-vacancies-message">{fetchError}</li>
          ) : vacancies.length === 0 ? (
            <li className="no-vacancies-message">No vacancies found</li>
          ) : (
            vacancies
              .filter(
                (vacancy) =>
                  (filter === 'all' ||
                    (filter === 'active' && vacancy.active) ||
                    (filter === 'inactive' && !vacancy.active)) &&
                  vacancy.job_title
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase())
              )
              .map((vacancy) => (
                <li
                  key={vacancy.pk}
                  className={`vacancy-item ${selectedVacancy && selectedVacancy.pk === vacancy.pk
                    ? 'selected'
                    : ''
                    }`}
                  onClick={() => handleVacancyClick(vacancy)}
                >
                  <div className="vacancy-content">
                    <h3 className="vacancy-title">{vacancy.job_title}</h3>
                    <p className="vacancy-active">{vacancy.active ? 'Active' : 'Inactive'}</p>
                  </div>
                  <div className="dropdown-container">
                    <button
                      className="dropdown-btn"
                      onClick={(e) => handleMenuOpen(e, vacancy)}
                    >
                      ⋮
                    </button>
                  </div>
                </li>
              ))
          )}
        </ul>
      )}

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <MenuItem
          onClick={() => {
            startEditingVacancy(currentVacancy);
            handleMenuClose();
          }}
        >
          Edit details
        </MenuItem>
        <MenuItem
          onClick={() => {
            copyLinkToClipboard(currentVacancy.pk);
            handleMenuClose();
          }}
        >
          Copy Link
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleDeleteVacancy(currentVacancy.pk);
            handleMenuClose();
          }}
          style={{ color: 'red' }}
        >
          Close Vacancy
        </MenuItem>
      </Menu>
    </aside>
  );
};

export default VacancyList;
