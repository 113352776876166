import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Snackbar, Alert } from '@mui/material';
import './Login.css';
import logo from '../../assets/kodelab-logo.png';
import loginGraphic from '../../assets/login-logo.png';

function Login({ handleLogin, handleLogoClick, currentApp }) {
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const onLoginClick = async () => {
    try {
      const result = await handleLogin(username, password);
      if (!result) {
        setOpenSnackbar(true);
      } else {
        setOpenSnackbar(false);
      }
    } catch (error) {
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const navigate = useNavigate();

  return (
    <div className="login-page">
      <img src={logo} alt="Kodelab Logo" className="top-logo" onClick={handleLogoClick} />
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          Login failed. Try again?
        </Alert>
      </Snackbar>

      <div className="login-container">
        <div className="login-left">
          <div className="login-graphic">
            <h2>{currentApp === 'Pickey' ? 'be Pickey, find the best' : 'Welcome to Kopi'}</h2>
            <img src={loginGraphic} alt="Login Graphic" className="login-image" />
          </div>
        </div>
        <div className="login-right">
          <div className="login-form">
            <h2>Log in</h2>
            <input
              type="text"
              placeholder="Enter your username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <input
              type="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <button className="login-submit-btn" onClick={onLoginClick}>
              Log in
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
