// src/utility/cookieHelpers.js

/**
 * Get the value of a specific cookie by name.
 * @param {string} name - The name of the cookie to retrieve.
 * @returns {string | undefined} - The value of the cookie, or undefined if not found.
 */

export const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    }
    return undefined;
  };
  