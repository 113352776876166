import React, { useState, useRef } from 'react';
import './InterviewDetails.css';

const InterviewDetails = ({ interviewDetails, isLoadingInterviews, interviewFetchError, onEvaluationReportClick, handleOpenVacancyPopup }) => {
  const [showAllInterviews, setShowAllInterviews] = useState(false);
  const listRef = useRef(null);

  const handleToggleShowAll = () => {
    const scrollTop = listRef.current.scrollTop;

    setShowAllInterviews(!showAllInterviews);

    setTimeout(() => {
      listRef.current.scrollTop = scrollTop;
    }, 0);
  };

  const displayedInterviews = showAllInterviews ? interviewDetails : interviewDetails.slice(0, 3);

  return (
    <div className="interview-details">
      {isLoadingInterviews ? (
        <p>Loading interviews...</p>
      ) : interviewFetchError ? (
        <p>{interviewFetchError}</p>
      ) : interviewDetails?.length === 0 ? (
        <p>No interviews found for this candidate.</p>
      ) : (
        <>
          <ul className="interview-list" ref={listRef}>
            {displayedInterviews.map((detail, index) => (
              <li key={index} className="interview-item">
                <div className="interview-header">
                  <div>
                  <h3 onClick={() => detail.vacancy.job_title && handleOpenVacancyPopup(detail)} style={{ cursor: detail.vacancy.job_title ? 'pointer' : 'default' }}>
                  {detail.vacancy.job_title || 'Job title not available'}
                  </h3>
                  <div className="interview-date">
                      {detail.interview?.date ? new Date(detail.interview.date * 1000).toLocaleDateString() : 'Date not available'}
                    </div>
                  </div>
                  <div className="interview-score">
                    {detail.interview?.evaluation?.overall_score?.toFixed(2) || 'N/A'}/5
                  </div>
                </div>
                <div className="interview-overview">
                  <h4>Overall evaluation</h4>
                  <p>{detail.interview?.evaluation?.summary || 'Summary not available'}</p>
                </div>
                <div className="interview-footer">
                  <div></div>
                  <button 
                    className="evaluation-report-link" 
                    onClick={() => onEvaluationReportClick(detail)}
                  >
                    Evaluation report
                  </button>
                </div>
              </li>
            ))}
          </ul>
          <div className="interview-details-footer">
            {interviewDetails?.length > 3 && (
              <button className="show-more-button" onClick={handleToggleShowAll}>
                {showAllInterviews ? 'Show Less' : 'Show More'}
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default InterviewDetails;
